<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Systemes</li>
                            </ol>
                        </nav>
                    </div>
                    <button class="btn btn-success mx-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Ajouter un systeme</button>
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Activite</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Systeme</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">RTO</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">RPO</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">MTPD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="systeme in systemes" :key="systeme.id">
                                        <td class="text-xs font-weight-bold text-center">{{showActivityName(systeme)}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{systeme.name}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{systeme.rto}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{systeme.rpo}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{systeme.mtpd}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasScrollingLabel">{{ activity_name }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div>
        <div class="form-group">
            <label for="rto">Activite</label>
            <select class="form-control" v-model="systeme.operationnel_id">
                <option v-for="activite in activites" :key="activite.id" :value="activite.id">{{ activite.details.activity_name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="name">Nom du systeme</label>
            <input type="text" class="form-control" v-model="systeme.name">
        </div>
        <div class="form-group">
            <label for="rto">RTO du systeme</label>
            <select class="form-control" v-model="systeme.rto">
                <option v-for="duration in durations" :key="duration.id" :value="duration.name">{{ duration.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="rpo">RPO du systeme</label>
            <select class="form-control" v-model="systeme.rpo">
                <option v-for="duration in durations" :key="duration.id" :value="duration.name">{{ duration.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="mtpd">MTPD du systeme</label>
            <select class="form-control" v-model="systeme.mtpd">
                <option v-for="duration in durations" :key="duration.id" :value="duration.name">{{ duration.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="switchInfra" v-model="systeme.infra">
                <label class="form-check-label" for="switchInfra">Infrastructure</label>
            </div>
        </div>
        <div class="form-group">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="switchServer" v-model="systeme.server">
                <label class="form-check-label" for="switchServer">Serveur</label>
            </div>
        </div>
        <div class="form-group">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="switchData" v-model="systeme.data">
                <label class="form-check-label" for="switchData">Donnees</label>
            </div>
        </div>
        <div class="form-group">
            <label for="comment">Fonction</label>
            <textarea v-model="systeme.comment" id="" cols="30" rows="10" class="form-control"></textarea>
        </div>
        <button @click="saveSysteme" class="btn btn-success">Enregistrer</button>
    </div>
  </div>
</div>

    </div>
</template>
<script>
import Choices from 'choices.js';
import API from './api'

    export default{
        data(){
            return{
                activites:[],
                entities: [],
                processes: [],
                durations:[],
                systemes:[],
                activity_systemes:[],
                systeme: {},
                activity_name:null,
                activity_rto:null,
                showForm:null,
                type: null,
                organisationel_id: null
            }
        },
        created(){
            if (this.$route.params.type == 'orga') {
                this.onGetEntities(this.$route.params.id)
            }
            if (this.$route.params.type == 'process') {
                this.onGetProcesses(this.$route.params.id)
            }
        },
        mounted(){
            this.onGetDurations(),
            this.getActivities(),
            this.onGetSystemes(this.$route.params.type, this.$route.params.id)
        },
        updated(){
            const selectElement = document.querySelector('.my-select');
            
            if(selectElement){
                new Choices(selectElement);
                selectElement.removeEventListener('change', this.myCallBack)
                selectElement.addEventListener('change', this.myCallBack);
            }
            
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async getActivities(){
                await API.getAllOperationnels().then(res =>{
                    if(res.data){
                        this.activites = this.$route.params.type == 'process' ? res.data.data.filter(obj => {return obj.universe.process_id == this.$route.params.id}) : res.data.data.filter(obj => {return obj.universe.entity_id == this.$route.params.id})
                    }
                })
            },
            async onGetProcesses(id){
                await API.getProcess(id).then(res => {
                    if(res.data.data){
                        this.processes = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetEntities(id){
                await API.getEntity(id).then(res => {
                    if(res.data.data){
                        this.entities = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async myCallBack(e){
                const selectedValue = e.target.value;
                if(selectedValue != 'all'){

                        await this.getActivities()
                        let theActivities = []
                        this.activites.forEach(element => {
                            if(this.entities.length > 0){
                                if(element.activity.entity_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                            if(this.processes.length > 0){
                                if(element.activity.process_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                        });
                        this.activites = theActivities
                    }else{
                        await this.getActivities()
                    }
            },
            async onGetDurations(){
                await API.getDrations().then(res =>{
                    if(res.data){
                        this.durations = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetSystemes(type, id){
                let myParams = {}
                myParams.type = type
                myParams.id = id
                await API.getSystemes(myParams).then(res =>{
                    if(res.data){
                        this.systemes = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async saveSysteme(){
                this.$route.params.type == 'process' ? this.systeme.process_id = parseInt(this.$route.params.id) : this.systeme.entity_id = parseInt(this.$route.params.id)
                
                await API.createSysteme(this.systeme).then(res =>{
                    if(res.data){                        
                        this.systeme = {}
                        this.showForm = false
                        this.onGetSystemes(this.$route.params.type, this.$route.params.id)
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            updateSystem(system){
                this.systeme = system
                this.showForm = true
            },
            showActivityName(system){                                
               const tempActivity = this.activites.find(obj => {return obj.id == system.operationnel_id})
                return tempActivity ? tempActivity.details.activity_name : ''
            }

        }
    }
</script>
<style>
    .breadcrumb-nav{
        width: fit-content;
    }
    .offcanvas-header .btn-close{
        color: white;
        background-color: green;
    }
    .my-cursor{
        cursor: pointer;
    }
</style>