<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                <ol class="breadcrumb">
                    <li @click="goTo('/organisme')" class="breadcrumb-item"><a href="#">Organisme</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Environement (I & E)</li>
                </ol>
            </nav>
            <div class="card">
                <div v-if="environments" class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center">Creation</th>
                                <th class="text-center">Forces</th>
                                <th class="text-center">Faiblesses</th>
                                <th class="text-center">Menaces</th>
                                <th class="text-center">Opportunites</th>
                                <th class="text-center">economique</th>
                                <th class="text-center">politique</th>
                                <th class="text-center">social</th>
                                <th class="text-center">technologique</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="environment in environments" :key="environment.id">
                                <td v-if="environment.created_at" class="text-center">{{environment.created_at}}</td>
                                <td v-else></td>
                                <td v-if="environment.strengths" class="text-center">{{environment.strengths}}</td>
                                <td v-else></td>
                                <td v-if="environment.weaknesses" class="text-center">{{environment.weaknesses}}</td>
                                <td v-else></td>
                                <td v-if="environment.threats" class="text-center">{{ environment.threats }}</td>
                                <td v-else></td>
                                <td v-if="environment.opportunities" class="text-center">{{ environment.opportunities }}</td>
                                <td v-else></td>
                                <td v-if="environment.economic" class="text-center">{{environment.economic}}</td>
                                <td v-else></td>
                                <td v-if="environment.politic" class="text-center">{{environment.politic}}</td>
                                <td v-else></td>
                                <td v-if="environment.social" class="text-center">{{ environment.social }}</td>
                                <td v-else></td>
                                <td v-if="environment.technologic" class="text-center">{{ environment.technologic }}</td>
                                <td v-else></td>
                                <td class="td-actions text-center">
                                    <button @click="goTo('/environment/'+ environment.id)"  type="button" rel="tooltip" class="btn btn-success btn-icon btn-sm " data-original-title="" title="">
                                        <i class="ni ni-circle-08 pt-1"></i>
                                    </button>
                                    <!-- <button type="button" rel="tooltip" class="btn btn-info btn-icon btn-sm " data-original-title="" title="">
                                        <i class="ni ni-settings-gear-65 pt-1"></i>
                                    </button>
                                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="" title="">
                                        <i class="ni ni-fat-remove pt-1"></i>
                                    </button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import API from '../api'

export default{
    data(){
        return{
           environments:[],
        }
    },
    mounted(){
        this.onGetEnvironments()
    },
    methods:{
        goTo(path){
            this.$router.push(path)
        },
        async onGetEnvironments(){
            await API.getEnvironments().then(res =>{
                if(res.data){
                    this.environments = res.data.data;
                }
            }).catch(err => {
                console.log('error >>>>', err.response.data)
            })
        },
    }
}
</script>
<style scoped>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>