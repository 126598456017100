<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Espace de travail</li>
                            </ol>
                        </nav>
                    </div>
                    <button class="btn btn-success mx-4" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Ajouter un espace</button>
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Personnel</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Type</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Places requises</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center" v-for="duration in durations" :key="duration.id">{{duration.name}}</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Materiels</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Commentaires</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="espace in espaces" :key="espace.id">
                                        <td class="text-xs font-weight-bold text-center">{{espace.personnel}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{espace.type}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{espace.places}}</td>
                                        <td class="text-xs font-weight-bold text-center" v-for="duration in espace.durations" :key="duration.id">{{ duration }}</td>
                                        <td class="text-xs font-weight-bold text-center">{{ espace.materials }}</td>
                                        <td class="text-xs font-weight-bold text-center">{{ espace.comment }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasScrollingLabel">{{ activity_name }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <p>Ajouter un espace de travail.</p>
    
    <div>
        <div class="form-group">
            <label for="name">Personnels</label>
            <select class="form-control" v-model="espace.personnel">
                <option v-for="personnel in personnels" :value="personnel.function" :key="personnel.function">{{ personnel.function }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="name">type</label>
            <select class="form-control" v-model="espace.type">
                <option value="standard">Standard</option>
                <option value="teletravail">Teletravail</option>
            </select>
        </div>
        <div class="form-group">
            <label for="places">Places requises</label>
            <input type="number" class="form-control" v-model="espace.places">
        </div>
        <div class="form-group" v-for="duration in durations" :key="duration.id">
            <label :for="duration.name">{{ duration.name }}</label>
            <input type="text" class="form-control" v-model="allDurations[duration.name.replace(/\s/g, '')]">
            <!-- <input type="text" class="form-control" v-model="espace.duration[duration.name]"> -->
        </div>
        <div class="form-group">
            <label for="materials">Materiels</label>
            <input type="text" class="form-control" v-model="espace.materials">
        </div>
        <div class="form-group">
            <label for="comment">Commentaires</label>
            <textarea v-model="espace.comment" id="" cols="30" rows="10" class="form-control"></textarea>
        </div>
        <button @click="saveEspace" class="btn btn-success">Enregistrer</button>
    </div>
  </div>
</div>

    </div>
</template>
<script>
import Choices from 'choices.js';
import API from './api'

    export default{
        data(){
            return{
                activites:[],
                entities: [],
                processes: [],
                espaces:[],
                personnels:[],
                activity_espaces:[],
                espace: {},
                activity_name:null,
                activity_rto:null,
                showForm:null,
                type: null,
                operationnel_id: null,
                allDurations:{}
            }
        },
        created(){
            if (this.$route.params.type == 'orga') {
                this.onGetEntities(this.$route.params.id)
            }
            if (this.$route.params.type == 'process') {
                this.onGetProcesses(this.$route.params.id)
            }
        },
        mounted(){
            this.getActivities(),
            this.onGetDurations(),
            this.onGetEspaces(this.$route.params.type, this.$route.params.id),
            this.onGetPersonnels(this.$route.params.type, this.$route.params.id)
        },
        updated(){
            const selectElement = document.querySelector('.my-select');
            
            if(selectElement){
                new Choices(selectElement);
                selectElement.removeEventListener('change', this.myCallBack)
                selectElement.addEventListener('change', this.myCallBack);
            }
            
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async getActivities(){
                await API.getAllOperationnels().then(res =>{
                    if(res.data){
                        this.activites = res.data.data
                    }
                })
            },
            async onGetProcesses(id){
                await API.getProcess(id).then(res => {
                    if(res.data.data){
                        this.processes = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetEntities(id){
                await API.getEntity(id).then(res => {
                    if(res.data.data){
                        this.entities = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async myCallBack(e){
                const selectedValue = e.target.value;
                if(selectedValue != 'all'){

                        await this.getActivities()
                        let theActivities = []
                        this.activites.forEach(element => {
                            if(this.entities.length > 0){
                                if(element.activity.entity_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                            if(this.processes.length > 0){
                                if(element.activity.process_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                        });
                        this.activites = theActivities
                    }else{
                        await this.getActivities()
                    }
            },
            async onGetDurations(){
                await API.getDrations().then(res =>{
                    if(res.data){
                        this.durations = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetEspaces(type, id){
                let myParams = {}
                myParams.type = type
                myParams.id = id
                await API.getEspaces(myParams).then(res =>{
                    if(res.data){
                        this.espaces = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetPersonnels(type, id){
                let myParams1 = {}
                myParams1.type = type
                myParams1.id = id
                await API.getPersonnels(myParams1).then(res =>{
                    if(res.data){
                        this.personnels = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            showEspace(espace){
                this.espace = espace                
            },
            async saveEspace(){
                this.espace.durations = this.allDurations

                if(!this.espace.id){
                    this.$route.params.type == 'process' ? this.espace.process_id = parseInt(this.$route.params.id) : this.espace.entity_id = parseInt(this.$route.params.id)
                }

                await API.createEspace(this.espace).then(res =>{
                    if(res.data){
                        this.espace = {}
                        this.onGetEspaces(this.$route.params.type, this.$route.params.id)
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            updateEspace(espace){
                this.espace = espace
                this.allDurations = espace.durations
                this.showForm = true
            }

        }
    }
</script>
<style>
    .breadcrumb-nav{
        width: fit-content;
    }
    .offcanvas-header .btn-close{
        color: white;
        background-color: green;
    }
    .my-cursor{
        cursor: pointer;
    }
</style>