import axios from "axios"
import { BASE_URL } from "../../server/Request"

export default {
    getGrille: (params = {}, headers = {}) => axios.get(BASE_URL + "grille", params, headers),
    getSectors: (params = {}, headers = {}) => axios.get(BASE_URL + "sector", params, headers),
    getImpacts: (params = {}, headers = {}) => axios.get(BASE_URL + "impact", params, headers),
    getDrations: (params = {}, headers = {}) => axios.get(BASE_URL + "durations", params, headers),
    getActivities: (params = {}, headers = {}) => axios.get(BASE_URL + "activity", params, headers),
    getActivity: (params = {}, headers = {}) => axios.get(BASE_URL + "activity/"+params, params, headers),
    getUniverses: (params = {}, headers = {}) => axios.get(BASE_URL + "universe", params, headers),
    getEntities: (params = {}, headers = {}) => axios.get(BASE_URL + "entities", params, headers),
    getEntity: (params = '', headers = {}) => axios.get(BASE_URL + "entity/"+params, headers),
    getProcesses: (params = {}, headers = {}) => axios.get(BASE_URL + "processuses", params, headers),
    getProcess: (params = '', headers = {}) => axios.get(BASE_URL + "process/"+params, headers),
    getCritiqActivities: (params = {}, headers = {}) => axios.post(BASE_URL + "critiques", params, headers),
    getCurrentProject: (params = {}, headers = {}) => axios.get(BASE_URL + "project", params, headers),
    getAllOperationnels: (params = {}, headers = {}) => axios.get(BASE_URL + "operationnels", params, headers),
    getSystemes: (params = {}, headers = {}) => axios.post(BASE_URL + "systemes", params, headers),
    getDatas: (params = {}, headers = {}) => axios.post(BASE_URL + "datas", params, headers),
    getInternes: (params = {}, headers = {}) => axios.post(BASE_URL + "internes", params, headers),
    getExternes: (params = {}, headers = {}) => axios.post(BASE_URL + "externes", params, headers),
    getProviders: (params = {}, headers = {}) => axios.get(BASE_URL + "providers", params, headers),
    getFunctions: (params = {}, headers = {}) => axios.get(BASE_URL + "workplaces", params, headers),
    getPersonnels: (params = {}, headers = {}) => axios.post(BASE_URL + "personnels", params, headers),
    getEspaces: (params = {}, headers = {}) => axios.post(BASE_URL + "espaces", params, headers),
    setNewAnalyze: (params = '', headers = {}) => axios.get(BASE_URL + "setNewAnalyze/"+ params, headers),
    createUniverse: (params = {}, headers = {}) => axios.post(BASE_URL + "universe", params, headers),
    setProjectType: (params = {}, headers = {}) => axios.post(BASE_URL + "setProjectType", params, headers),
    createImpactOp: (params = {}, headers = {}) => axios.post(BASE_URL + "createImpactOp", params, headers),
    createSysteme: (params = {}, headers = {}) => axios.post(BASE_URL + "createSysteme", params, headers),
    createPersonnel: (params = {}, headers = {}) => axios.post(BASE_URL + "createPersonnel", params, headers),
    createEspace: (params = {}, headers = {}) => axios.post(BASE_URL + "createEspace", params, headers),
    createData: (params = {}, headers = {}) => axios.post(BASE_URL + "createData", params, headers),
    createInterne: (params = {}, headers = {}) => axios.post(BASE_URL + "createInterne", params, headers),
    createExterne: (params = {}, headers = {}) => axios.post(BASE_URL + "createExterne", params, headers),
    addCompetence: (params = {}, headers = {}) => axios.post(BASE_URL + "addCompetence", params, headers),
}