<script>
</script>
<template>
  <div class="card h-100 mb-4">
    <div class="card-header pb-0 px-3">
      <div class="row" v-if="activity.entity">
        <div class="col-md-6">
          <h6 class="mb-0">Entite</h6>
        </div>
        <div class="col-md-6 d-flex justify-content-end align-items-center">
          <!-- <i class="far fa-calendar-alt me-2" aria-hidden="true"></i> -->
          <small>{{ activity.entity.name }}</small>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">
      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Newest
      </h6>
      
      
    </div>
  </div>
</template>
<script>
     export default {
        props: {
          activity:Object,
        },
    }
</script>
