<template>
    <div class="py-4 container-fluid">
        <div class="row">
                <nav v-if="etape" aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                    <ol class="breadcrumb">
                        <li @click="goTo('/structure')" class="breadcrumb-item"><a href="#">Structure</a></li>
                        <li @click="goTo('/structure/phase/'+etape.phase_id)" class="breadcrumb-item"><a href="#">Phase</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ etape.name }}</li>
                    </ol>
                </nav>
            <h1 v-if="etape" class="text-white text-center">{{ etape.name }}</h1>
            <div v-for="tache in taches" :key="tache.id"  class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <div class="bia-text">{{tache.title}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import API from './api'

    export default{
        data(){
            return{
                taches:[],
                etape_id:'',
                etape:{},
            }
        },
        mounted(){
            if(this.$route.params.id){
                this.etape_id = this.$route.params.id
                this.onGetTaches(this.etape_id)
            }
            
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async onGetTaches(id){
                await API.getTaches(id).then(res =>{
                    if(res.data){
                        this.taches = res.data.data;
                        this.etape = res.data.etape
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
        }
    }
</script>
<style scoped>
    .bia-box{
        width: 500px;
        padding: 50px;
        margin: 20px;
    }
    .bia-text{
        font-weight: bold;
        font-size: 25px;
    }
    .breadcrumb-nav{
        width: fit-content;
    }
</style>