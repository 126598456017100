<template>
    <div class="py-4 container-fluid">
        <br><br><br><br><br><br>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Impact-operationnel</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="card-body ">
                        <button type="button" class="btn btn-success mx-2">
                            {{ type == 'orga' ? 'Organigramme' : 'Processus' }}
                        </button>
                            
                            <div class="form-group">
                                <select v-if="entities.length > 0" name="my-select" class="form-control my-select" id="my-select">
                                    <option value="all">Tous</option>
                                         <option :value="entity.id" v-for="entity in entities" :key="entity.id">{{ entity.name }}</option>
                                </select>
                                <select v-if="processes.length > 0" name="my-select" class="form-control my-select">
                                    <option value="all">Tous</option>
                                    <option :value="process.id" v-for="process in processes" :key="process.id">{{ process.name }}</option>
                                </select>
                            </div>
                            <form action="#">
                                <div class="form-group" v-if="activites.length">
                                    <label for="">Activite</label>
                                    <select class="form-control" v-model="rtoData.activity" @change="getUniverse">
                                        <option v-for="activity in activites" :key="activity.id" :value="activity.id">{{ activity.activity.name }}</option>
                                    </select>
                                </div>
                            </form>
                            <em class="text-center text-danger" v-if="errMsg">Cette valeur doit etre comprise entre <b>{{ lowestImpact }}</b> et <b>{{ highestImpact }}</b></em>
                        <div class="table-responsive" v-if="rtoData.activity">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-left text-xs font-weight-bolder">Type d'impact</th>
                                        <th v-for="duration in durations" :key="duration.id" class="text-uppercase text-center text-xs font-weight-bolder">{{ duration.name }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="sector in sectors" :key="sector.id">
                                        <td class="text-xs font-weight-bold text-center">{{ sector.name }} ({{ sector.percentage }})%</td>
                                        <td v-for="duration in durations" :key="duration.id" class="text-xs font-weight-bold text-center"> <input min="0" @focusout="checkValue" type="number" :id="sector.name+'/'+duration.name" v-model="rtoData[sector.name+'/'+duration.name]" class="form-control"> </td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs font-weight-bold text-center">Impact le plus eleve</td>
                                        <td v-for="duration in durations" :key="duration.id">
                                            <input type="number" readonly v-model="impactElv[duration.name]" class="form-control">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs font-weight-bold text-center">RTO de l'activite</td>
                                        
                                        <!-- <td :colspan="activites.length"><input type="text" v-model="rtoData.rto" readonly placeholder="RTO de l'activite" class="form-control"></td> -->
                                        <td :colspan="activites.length">
                                            <select name="rtoData-rto" id="rtoData-rto" v-model="rtoData.rto" class="form-control" @change="setExactDate($event, 'rto')">
                                                <option v-for="duration in durations" :key="duration.id" :value="duration.name">{{ duration.name }}</option>
                                            </select>
                                        </td>
                                        <td :colspan="children.length">
                                            <select name="rtoData-rto" id="rtoData-rto" v-model="rtoData.rtoExact" class="form-control">
                                                <option v-for="child in children" :key="child" :value="child">{{ child }}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs font-weight-bold text-center">MTPD de l'activite</td>
                                        <!-- <td class="text-xs font-weight-bold text-center" :colspan="activites.length"> <input type="text" v-model="rtoData.mtpd" readonly class="form-control"> </td> -->
                                        <td class="text-xs font-weight-bold text-center" :colspan="activites.length">
                                                <select name="rtoData-mtpd" id="rtoData-mtpd" v-model="rtoData.mtpd" class="form-control" @change="setExactDate($event, 'mtpd')">
                                                    <option v-for="duration in durations" :key="duration.id" :value="duration.name">{{ duration.name }}</option>
                                                </select>
                                        </td>
                                        <td :colspan="children2.length">
                                            <select name="rtoData-rto" id="rtoData-rto" v-model="rtoData.mtpdExact" class="form-control">
                                                <option v-for="child2 in children2" :key="child2" :value="child2">{{ child2 }}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs font-weight-bold text-center">Commentaires</td>
                                        <td class="text-xs font-weight-bold text-center" :colspan="activites.length"><textarea v-model="rtoData.comment" class="form-control"></textarea></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button v-if="rtoData.activity" class="btn btn-secondary mt-4" @click="saveRTO">Enregistrer</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-danger" id="exampleModalLabel">ATTENTION</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="text-danger">
                        Cette valeur doit etre comprise entre <b>{{ lowestImpact }}</b> et <b>{{ highestImpact }}</b>
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" @click="myModal.hide()">Fermer</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import * as bootstrap from 'bootstrap'
import Choices from 'choices.js';
import API from './api'

export default{
        data(){
            return{
                impacts:[],
                sectors:[],
                durations:[],
                activites:[],
                type: null,
                processes: [],
                entities: [],
                rtoData: {},
                impactElv: {},
                universe: {},
                highestImpact: null,
                lowestImpact: null,
                myModal: null,
                errMsg:0,
                operationnel:{},
                operationnels:[],
                children: [],
                children2: [],
            }
        },
        updated(){
            const selectElement = document.querySelector('.my-select');
            
            if(selectElement){
                new Choices(selectElement);
                selectElement.removeEventListener('change', this.myCallBack)
                selectElement.addEventListener('change', this.myCallBack);
            }
            
        },
        mounted(){
            this.onGetDurations(),
            this.onGetImpacts(),
            this.onGetSectors(),
            this.onGetAllOperationnels(),
            this.type = this.$route.params.type
            if (this.$route.params.type == 'orga') {
                this.onGetEntities(this.$route.params.id)
            }
            if (this.$route.params.type == 'process') {
                this.onGetProcesses(this.$route.params.id)
            }
            this.myModal = new bootstrap.Modal(document.getElementById('exampleModal'))
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async onGetImpacts(){
                await API.getImpacts().then(res =>{
                    if(res.data){
                        this.impacts = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
           async onGetSectors(){
                await API.getSectors().then(res =>{
                    if(res.data){
                        this.sectors = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
           async onGetDurations(){
                await API.getDrations().then(res =>{
                    if(res.data){
                        this.durations = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async getActivities(type, id){
                let myParam = {}
                myParam.type = type
                type == 'process' ? myParam.process_id = id : myParam.entity_id = id
                await API.getCritiqActivities(myParam).then(res =>{
                    if(res.data){
                        this.activites = res.data.data
                    }
                })
            },
            async onGetProcesses(id){
                await API.getProcess(id).then(res => {
                    if(res.data.data){
                        this.processes.push(res.data.data)
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetEntities(id){
                await API.getEntity(id).then(res => {
                    if(res.data.data){
                        this.entities.push(res.data.data)
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async myCallBack(e){
                this.rtoData = {}
                
                await this.getActivities(this.$route.params.type, this.$route.params.id)
                const selectedValue = e.target.value;
                    if(selectedValue != 'all'){
                        this.activites = this.entities.length > 0 ? this.activites.filter(obj => {return obj.activity.entity_id == selectedValue}) : this.activites.filter(obj => {return obj.activity.process_id == selectedValue})
                    }else{
                        await this.getActivities(this.$route.params.type, this.$route.params.id)
                    }

            },
            checkValue(e){
                const inputVal = e.target.value
                const inputId = e.target.id
                const tempUniv = this.activites.find(el => {return el.id == this.rtoData.activity})
                this.highestImpact = tempUniv.universes.find(univ => {return univ.sector_name == inputId.split('/')[0]}).ponderation
                let tempArr = []
                if(inputVal < this.lowestImpact || inputVal > this.highestImpact){
                    e.target.focus()
                    e.target.val = null
                    this.errMsg = 1
                    return
                }
                else{
                    const myTempImpColor = this.impacts.find(el => {return el.ponderation == inputVal}).color
                    e.target.style.backgroundColor = myTempImpColor
                    this.errMsg = 0
                    this.sectors.forEach(sec => {
                        this.durations.forEach(dur => {
                            if(this.rtoData[sec.name+'/'+dur.name] != undefined && inputId.split('/')[1] == dur.name){
                                tempArr.push(this.rtoData[sec.name+'/'+dur.name])
                            }
                        });
                    });
                    this.impactElv[inputId.split('/')[1]] = Math.max(...tempArr)

                    const myTempImp = this.impacts.find(el => {return el.rto != null}).ponderation
                    const myRtoVal = this.getKeyByValue(this.rtoData, parseInt(myTempImp))
                    this.rtoData.rto = myRtoVal.split('/')[1]

                    this.getMtpd()
                }
            },
            getKeyByValue(object, value) {
                return Object.keys(object).reverse().find(key => key != 'activity' && object[key] >= value);
            },
            getMtpd(){
                const tempActivity = this.activites.find(el => {return el.id == this.rtoData.activity})
                const tempUniverse = tempActivity.universes
                this.rtoData.mtpd = this.getKeyByValue(this.rtoData, Math.max(...tempUniverse.map(el => el.ponderation))).split('/')[1]
            },
            getUniverse(e){
                
                 this.universe = this.activites.find(el =>{return el.id == e.target.value}) 
                                 
                 let tempRtoData = this.operationnels.find(el =>{return this.universe.id == el.details.activity})
                 
                 if(tempRtoData){
                    this.rtoData = tempRtoData.details
                    this.impactElv = tempRtoData.details.impactElv
                 }else{
                    this.rtoData = {}
                    this.impactElv = {}
                    this.rtoData.activity = e.target.value
                    const highestDurVal = Math.max(...this.durations.map(o => o.rang))
                    this.highestImpact = Math.max(...this.impacts.map(o => o.ponderation))
                    this.lowestImpact = Math.min(...this.impacts.map(o => o.ponderation))
                    const highestDur = this.durations.find(el => {return el.rang == highestDurVal})
                    
                        this.sectors.forEach(sector =>{
                            let tempObj = this.universe.universes.find(obj => {
                                return obj.sector_name == sector.name
                            })
                            this.rtoData[sector.name+'/'+highestDur.name] = tempObj.ponderation
                        })
                 }
                
            },
            async saveRTO(){
                this.rtoData.impactElv = this.impactElv
                this.rtoData.activity_name = this.universe.activity.name
                await API.createImpactOp(this.rtoData).then(res =>{
                    if(res.data){
                        this.change_input_color();
                        this.rtoData = {}
                        this.operationnel = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            change_input_color() {
                var inputs = document.getElementsByTagName('input');
                for (const input of inputs) {
                    input.style.backgroundColor = ''
                }
            },
            setExactDate(e, type){
                const theDuration = this.durations.find(dur => {return dur.name == e.target.value})
                if(type == 'rto'){
                    this.children = []
                    for (let i = theDuration.start; i <= theDuration.end; i++) {
                        this.children.push(i);
                    }
                }else{
                    this.children2 = []
                    for (let i = theDuration.start; i <= theDuration.end; i++) {
                        this.children2.push(i);
                    }
                }

                    
            },
            async onGetAllOperationnels(){
                await API.getAllOperationnels().then(res =>{
                    if(res.data.data){
                        this.operationnels = res.data.data                        
                    }
                }).catch(err =>{
                    console.log(err);
                })
            }

        }
    }
</script>
<style scoped>
    .breadcrumb-nav{
        width: fit-content;
    }
    .duration{
        border-radius: 5px;
        padding: 2px;
    }
</style>