<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Dependances internes</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Activites</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">RTO</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Dependances</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="activity in activites" :key="activity.id">
                                        <td class="text-xs font-weight-bold text-center">{{activity.details.activity_name}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{activity.rto}}</td>
                                        <td class="text-xs font-weight-bold text-center">
                                            <button @click="showData(activity)" class="btn btn-success" type="button">Voir <i class="ni ni-bold-right"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

<div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasScrollingLabel">{{ activity_name }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div v-if="realActivity">
        <p>Entite: <span class="badge badge-info m-1 my-cursor">{{ realActivity.entity ? realActivity.entity.name : '' }}</span></p>
        <p>Process: <span class="badge badge-info m-1 my-cursor">{{ realActivity.process ? realActivity.process.name : '' }}</span></p>
    </div>
    <br>
    <button class="btn btn-success" @click="setShowForm">Ajouter une dependance</button>
    <div v-if="showForm">
        <div class="form-group d-flex justify-content-between">
            <div><button @click="setIsEntity" type="button" class="btn">Entites</button></div>
            <div><button @click="setIsProcess" type="button" class="btn">Processus</button></div>
            <div><button @click="setIsOther" type="button" class="btn">Autre</button></div>
        </div>
        <div v-if="isProcess" class="form-group">
            <label for="process_id">Choisir un processus</label>
            <select v-model="interne.depend_process_id" class="form-control" @change="setActivity">
                <option v-for="process in allProcesses" :value="process.id" :key="process.id">{{ process.name }}</option>
            </select>
        </div>
        <div v-if="isEntity" class="form-group">
            <label for="entity_id">Choisir une entite</label>
            <select v-model="interne.depend_entity_id" class="form-control" @change="setActivity">
                <option v-for="entity in allEntities" :value="entity.id" :key="entity.id">{{ entity.name }}</option>
            </select>
        </div>
        <div class="form-group" v-if="realActivities.length">
            <label for="activity">Choisir une activite</label>
            <select class="form-control" v-model="interne.activity">
                <option v-for="activity in realActivities" :key="activity.id" :value="activity.name">{{ activity.name }}</option>
            </select>
        </div>
        <div class="form-group" v-if="isOther">
            <label for="other">Autre</label>
            <input type="text" class="form-control" v-model="interne.depend_other">
        </div>
        <div class="form-group">
            <label for="rto">RTO</label>
            <select class="form-control" v-model="interne.rto">
                <option v-for="duration in durations" :key="duration.id" :value="duration.name">{{ duration.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="comment">Commentaires</label>
            <textarea v-model="interne.comment" cols="30" rows="10" class="form-control"></textarea>
        </div>
        <button @click="saveInterne" class="btn btn-success">Enregistrer</button>
    </div>
    <h6 class="text-center">Liste des dependances</h6>
    <div class="table-responsive">
        <table class="table align-items-center">
            <thead>
                <tr>
                    <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Activite</th>
                    <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">RTO</th>
                </tr>
            </thead>
            <tbody>
                <tr class="my-cursor" v-for="activity in activity_internes" :key="activity.id" @click="updateData(activity)">
                    <td class="text-xs font-weight-bold text-center">{{activity.depend_other ? activity.depend_other : activity.activity}}</td>
                    <td class="text-xs font-weight-bold text-center">{{activity.rto }}</td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
</div>

    </div>
</template>
<script>
import * as bootstrap from 'bootstrap';
import Choices from 'choices.js';
import API from './api';

    export default{
        data(){
            return{
                activites:[],
                entities: [],
                allEntities: [],
                allProcesses: [],
                processes: [],
                durations:[],
                internes:[],
                interne: {},
                realActivity: {},
                realActivities: [],
                activity_internes: [],
                activity_name:null,
                activity_rto:null,
                showForm:null,
                type: null,
                operationnel_id:null,
                isProcess:null,
                isEntity:null,
                isOther:null,
            }
        },
        created(){
            if (this.$route.params.type == 'orga') {
                this.onGetEntities(this.$route.params.id)
            }
            if (this.$route.params.type == 'process') {
                this.onGetProcesses(this.$route.params.id)
            }
        },
        mounted(){
            this.onGetDurations(),
            this.getActivities(),
            this.onGetInternes(this.$route.params.type, this.$route.params.id),
            this.getRealActivities(),
            this.onGetAllProcesses(),
            this.onGetAllEntities()
        },
        updated(){
            const selectElement = document.querySelector('.my-select');
            
            if(selectElement){
                new Choices(selectElement);
                selectElement.removeEventListener('change', this.myCallBack)
                selectElement.addEventListener('change', this.myCallBack);
            }
            
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async getActivities(){
                await API.getAllOperationnels().then(res =>{
                    if(res.data){
                        this.activites = this.$route.params.type == 'process' ? res.data.data.filter(obj => {return obj.universe.process_id == this.$route.params.id}) : res.data.data.filter(obj => {return obj.universe.entity_id == this.$route.params.id})
                    }
                })
            },
            async getActivity(activity_id){
                await API.getActivity(activity_id).then(res =>{
                    if(res.data){
                        this.realActivity = res.data.data
                    }
                })
            },
            async getRealActivities(){
                await API.getActivities().then(res =>{
                    if(res.data){
                        this.realActivities = res.data.data
                    }
                })
            },
            async onGetProcesses(id){
                await API.getProcess(id).then(res => {
                    if(res.data.data){
                        this.processes = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetEntities(id){
                await API.getEntity(id).then(res => {
                    if(res.data.data){
                        this.entities = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetAllEntities(){
                await API.getEntities().then(res => {
                    if(res.data.data){
                        this.allEntities = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetAllProcesses(){
                await API.getProcesses().then(res => {
                    if(res.data.data){
                        this.allProcesses = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            setActivity(e){
                const tempActivities = this.interne.depend_entity_id != null ? this.allEntities.find(obj => {return obj.id == e.target.value}) : this.allProcesses.find(obj => {return obj.id == e.target.value})
                this.realActivities = tempActivities.activities
            },
            async myCallBack(e){
                const selectedValue = e.target.value;
                if(selectedValue != 'all'){

                        await this.getActivities()
                        let theActivities = []
                        this.activites.forEach(element => {
                            if(this.entities.length > 0){
                                if(element.activity.entity_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                            if(this.processes.length > 0){
                                if(element.activity.process_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                        });
                        this.activites = theActivities
                    }else{
                        await this.getActivities()
                    }
            },
            async onGetDurations(){
                await API.getDrations().then(res =>{
                    if(res.data){
                        this.durations = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetInternes(type, id){
                let myParams = {}
                myParams.type = type
                myParams.id = id
                await API.getInternes(myParams).then(res =>{
                    if(res.data){
                        this.internes = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            setShowForm(){
                this.showForm = !this.showForm
                this.data = {}
                this.realActivities = []
            },
            async showData(activity){
                await this.getActivity(activity.universe.activity_id)
                this.activity_name = activity.details.activity_name
                this.operationnel_id = activity.id
                
                var myOffcanvas = document.getElementById('offcanvasScrolling')
                var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
                                  bsOffcanvas.show()

                this.activity_internes = this.internes.filter(obj => {return obj.operationnel_id == activity.id})
            },
            async saveInterne(){
                if(!this.interne.id){
                    this.$route.params.type == 'process' ? this.interne.process_id = parseInt(this.$route.params.id) : this.interne.entity_id = parseInt(this.$route.params.id)
                    this.interne.operationnel_id = this.operationnel_id
                }
                
                await API.createInterne(this.interne).then(res =>{
                    if(res.data){
                        this.internes = res.data.data;
                        this.interne = {}
                        this.activity_internes.push(res.data.data)
                        this.showForm = false
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            updateData(data){
                this.interne = data
                this.showForm = true
            },
            showActivityName(data){
               const tempActivity = this.activites.find(obj => {return obj.id == data.operationnel_id})
                return tempActivity.details.activity_name
            },
            getInputs(activity_id){
                console.log(activity_id);
            },
            setIsEntity(){
                this.isEntity = 1
                this.isProcess = null
                this.isOther = null
                this.interne.depend_process_id = null
                this.interne.depend_other = null
                this.realActivities = []
            },
            setIsProcess(){
                this.isEntity = null
                this.isOther = null
                this.isProcess = 1
                this.interne.depend_entity_id = null
                this.interne.depend_other = null
                this.realActivities = []
            },
            setIsOther(){
                this.isOther = 1
                this.isEntity = null
                this.isProcess = null
                this.interne.depend_process_id = null
                this.interne.depend_entity_id = null
                this.realActivities = []
            },

        }
    }
</script>
<style>
    .breadcrumb-nav{
        width: fit-content;
    }
    .offcanvas-header .btn-close{
        color: white;
        background-color: green;
    }
    .my-cursor{
        cursor: pointer;
    }
</style>