<template>
    <div class="py-4 container-fluid">
        <br><br><br><br><br><br>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/BIA')" class="breadcrumb-item"><a href="#">BIA</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Univers des activites</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="card-body ">
                        <!-- <button class="btn btn-success mb-4" @click="addRow">Ajouter</button> -->
                        <button type="button" class="btn btn-success mx-2">
                            {{ type == 'orga' ? 'Organigramme' : 'Processus' }}
                        </button>
                            
                            <div class="form-group">
                                <select v-if="entities.length > 0" name="my-select" class="form-control my-select" id="my-select">
                                    <option value="all">Tous</option>
                                         <option :value="entity.id" v-for="entity in entities" :key="entity.id">{{ entity.name }}</option>
                                </select>
                                <select v-if="processes.length > 0" name="my-select" class="form-control my-select">
                                    <option value="all">Tous</option>
                                    <option :value="process.id" v-for="process in processes" :key="process.id">{{ process.name }}</option>
                                </select>
                            </div>
                            <em v-if="errMsg" class="text-danger">{{ errMsg }}</em>
                            <form action="#" v-if="inputData">
                                <div v-for="inputDa in inputData" :key="inputDa.id" class="row">
                                    <div class="form-group col-md-12">
                                        <label for="">Activite</label>
                                        <select class="form-control" v-model="objectData.activity">
                                            <option v-for="activity in activities" :key="activity.id" :value="activity.id">{{ activity.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-2" v-if="objectData.activity">
                                        <label for="">externalisee</label>
                                        <select class="form-control" v-model="objectData.extern">
                                            <option>Oui</option>
                                            <option>Non</option>
                                        </select>
                                    </div>
                                    <div v-for="sector in sectors" :key="sector.id" class="form-group col-md-2">
                                        <label for="" v-if="objectData.activity">{{ sector.name }}</label>
                                        <select v-if="objectData.activity" class="form-control" @change="setUniverse($event, sector)" v-model="objectData[sector.name]">
                                            <option value=""></option>
                                            <option v-for="impact in impacts" :value="impact.ponderation"  :key="impact.id">{{ impact.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6" v-if="objectData.activity">
                                        <label for="">Impact calcules (%)</label>
                                        <input type="text" readonly class="form-control" v-model="objectData.calcul">
                                    </div>
                                    <div class="form-group col-md-6" v-if="objectData.activity">
                                        <label for="">Commentaires</label>
                                        <input type="text" class="form-control" v-model="comment">
                                    </div>
                                    <div class="col-12" v-if="objectData.activity" style="display: flex; justify-content: space-around;">
                                        <button type="button" @click="setRow(activities)" class="btn btn-primary btn-block">Enregistrer</button>
                                        <button type="button" @click="reset()" class="btn btn-danger btn-block">Reset</button>
                                    </div>
                                    <hr>
                                </div>
                            </form>
                        <div class="table-responsive">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder">Activites</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder">externalisee</th>
                                        <th v-for="sector in tableHead" :key="sector.id" style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder">{{sector.sector_name}} - {{sector.percentage}}%</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder">Impacts calcules</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder">Commentaires</th>
                                    </tr>
                                </thead>
                                <tbody v-if="realUniverses">
                                    <tr v-for="universe in realUniverses" :key="universe.id">
                                        <td class="text-xs font-weight-bold text-center">{{ universe.activity.name }}</td>
                                        <td class="text-xs font-weight-bold text-center">{{ universe.isExternalised ? 'Oui': 'Non' }}</td>
                                        <td v-for="impact in universe.universes" :key="impact.id" class="text-xs font-weight-bold text-center">{{ impact.impact_name }}</td>
                                        <td class="text-xs font-weight-bold text-center">{{ universe.calcul }}</td>
                                        <td class="text-xs font-weight-bold text-center">{{universe.comment}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
// import baseButton from '../../components/ArgonButton'
import Choices from 'choices.js';
import * as bootstrap from 'bootstrap'
import API from './api'

export default{
    // components: {
    //     baseButton,
    // },
        data(){
            return{
                myModals: false,
                sectors: [],
                impacts: [],
                tempImpacts:{},
                activities: [],
                theActivities: [],
                universes:[],
                inputData:[],
                calcul:0,
                comment:'',
                objectData:{},
                realUniverses:[],
                tableHead:[],
                processes: [],
                entities: [],
                type: '',
                entity_id:null,
                errMsg: '',
            }
        },
        created(){
            console.log('created');
            
            this.onGetImpacts(),
            this.onGetSectors(),
            // this.onGetActivities(),
            this.onGetUniverses()
            this.type = this.$route.params.type
            if (this.$route.params.type == 'orga') {
                this.onGetEntities(this.$route.params.id)
            }
            if (this.$route.params.type == 'process') {
                console.log('process');
                this.onGetProcesses(this.$route.params.id)
            }
        },
        updated(){
            const selectElement = document.querySelector('.my-select');
            
            if(selectElement){
                new Choices(selectElement);
                selectElement.removeEventListener('change', this.myCallBack)
                selectElement.addEventListener('change', this.myCallBack);
            }
            
        },
        
        mounted(){

            

        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async onGetImpacts(){
                await API.getImpacts().then(res =>{
                    if(res.data){
                        this.impacts = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
           async onGetSectors(){
                await API.getSectors().then(res =>{
                    if(res.data){
                        this.sectors = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
           async onGetActivities(){
                await API.getActivities().then(res =>{
                    if(res.data){
                        this.activities = res.data.data;
                        let universe = {}
                        this.activities.forEach(activity => {
                            universe = {}
                            universe['name'] = activity.name
                            universe['activities'] = this.activities
                            this.sectors.forEach(element => {
                                    universe[element.name]=''

                            });
                            this.universes.push(universe)
                        });
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            setUniverse(e, sector){
                this.calcul = 0
                this.tempImpacts[sector.name] =  this.calcul + (e.target.value * sector.percentage)
                if(this.impacts.length == Object.keys(this.tempImpacts).length){
                    for (const temp in this.tempImpacts) {
                        this.calcul = this.calcul + this.tempImpacts[temp];
                    }
                }
                this.objectData.calcul = this.calcul / Object.keys(this.tempImpacts).length
            },
            addRow(activitys = []){
                if(this.inputData) this.inputData = []
                if(activitys) this.activities = activitys
                this.objectData.activity = ''
                this.objectData.extern = ''
                this.sectors.forEach(sec => {
                    this.objectData[sec.name] = ''
                });
                this.objectData.calcul = ''
                this.objectData.comment = ''
                if(this.type == 'orga') this.objectData.type = 'organigramme'
                if(this.type == 'process') this.objectData.type = 'processus'
                this.inputData.push(this.objectData)
                
            },
            async setRow(activites = []){
                this.objectData.comment = this.comment
                this.$route.params.type == 'organigramme' ? this.objectData.entity_id = this.$route.params.id : this.objectData.process_id = this.$route.params.id
                
                await API.createUniverse(this.objectData).then(res =>{
                    if(res.data.data){
                        this.addRow(activites)
                        this.setRealUniverses(this.entity_id)
                    }else{
                        if(res.data.msg){
                            this.errMsg = "Cette activite existe deja dans l'univers"
                        }else{
                            console.log(res.data);
                        }
                    }
                    
                })
            },
            reset(){
                this.inputData = []
            },
            async onGetUniverses(){
                await API.getUniverses().then(res => {
                    if(res.data.data){
                        this.realUniverses = res.data.data
                        this.tableHead = res.data.data[0].universes
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetProcesses(id){
                await API.getProcess(id).then(res => {
                    if(res.data.data){
                        this.processes.push(res.data.data)
                        
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetEntities(){
                await API.getEntities().then(res => {
                    if(res.data.data){
                        this.entities = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            showSelectedActivities(data){
                this.theActivities.push(data.activities)
                var myModal = new bootstrap.Modal(document.getElementById('exampleModal1'))
                myModal.hide()
            },
            async setRealUniverses(id){
                await this.onGetUniverses()
                this.realUniverses = this.entity_id != null ? this.realUniverses.filter(obj => {return obj.activity.entity_id == id}) : this.realUniverses.filter(obj => {return obj.activity.process_id == id})
            },
            myCallBack(e){
                const selectedValue = e.target.value;
                    if(selectedValue != 'all'){
                        const myElement = this.entities.length > 0 ? this.entities.find(obj => {return obj.id == selectedValue}) : this.processes.find(obj => {return obj.id == selectedValue})
                        this.addRow(myElement.activities)
                        this.entity_id = this.entities.length > 0 ? selectedValue : null
                        this.setRealUniverses(selectedValue)
                    }else{
                        this.onGetUniverses()
                    }
                    
            }
        }
    }
</script>
<style scoped>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>