<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Activites critiques</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="form-group container">
                        <select v-if="entities.length > 0" name="my-select" class="form-control my-select" id="my-select">
                            <option value="all">Tous</option>
                            <option :value="entity.id" v-for="entity in entities" :key="entity.id">{{ entity.name }}</option>
                        </select>
                        <select v-if="processes.length > 0" name="my-select" class="form-control my-select">
                            <option value="all">Tous</option>
                            <option :value="process.id" v-for="process in processes" :key="process.id">{{ process.name }}</option>
                        </select>
                    </div>
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Nom</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Impact Calcule</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="activity in activites" :key="activity.id">
                                        <td class="text-xs font-weight-bold text-center">{{activity.activity.name}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{activity.calcul}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Choices from 'choices.js';
import API from './api'

    export default{
        data(){
            return{
                activites:[],
                entities: [],
                processes: [],
                type: null,
            }
        },
        created(){
            if (this.$route.params.type == 'orga') {
                this.onGetEntities(this.$route.params.id)
            }
            if (this.$route.params.type == 'process') {
                this.onGetProcesses(this.$route.params.id)
            }
        },
        mounted(){
            this.getActivities(this.$route.params.type, this.$route.params.id)
        },
        updated(){
            const selectElement = document.querySelector('.my-select');
            
            if(selectElement){
                new Choices(selectElement);
                selectElement.removeEventListener('change', this.myCallBack)
                selectElement.addEventListener('change', this.myCallBack);
            }
            
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async getActivities(type, id){
                let myParam = {}
                myParam.type = type
                type == 'process' ? myParam.process_id = id : myParam.entity_id = id

                await API.getCritiqActivities(myParam).then(res =>{
                    if(res.data){
                        this.activites = res.data.data
                    }
                })
            },
            async onGetProcesses(id){
                await API.getProcess(id).then(res => {
                    if(res.data.data){
                        this.processes.push(res.data.data)
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetEntities(id){
                await API.getEntities(id).then(res => {
                    if(res.data.data){
                        this.entities.push(res.data.data)
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async myCallBack(e){
                const selectedValue = e.target.value;
                if(selectedValue != 'all'){

                        await this.getActivities()
                        let theActivities = []
                        this.activites.forEach(element => {
                            if(this.entities.length > 0){
                                if(element.activity.entity_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                            if(this.processes.length > 0){
                                if(element.activity.process_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                        });
                        this.activites = theActivities
                    }else{
                        await this.getActivities()
                    }
            }
        }
    }
</script>
<style>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>