<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Personnels</li>
                            </ol>
                        </nav>
                    </div>
                    <button class="btn btn-success mx-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Ajouter un personnel</button>
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Activites</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">fonction</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Nombre de personnes</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Priorite</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">RTO</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">SPOF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="personnel in personnels" :key="personnel.id">
                                        <td @click="setShowForm(personnel)" class="text-xs font-weight-bold text-center" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">{{personnel.activity}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{personnel.function}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{personnel.number}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{personnel.priority}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{personnel.rto}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{personnel.spof}}</td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
  <div class="offcanvas-header">
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <p>Processus: {{process_name}}</p>
    <!-- <button class="btn btn-success" @click="setShowForm">Ajouter un personnel</button> -->
    <div>
        <div class="form-group">
            <label for="name">Activite</label>
            <select class="form-control" v-model="personnel.activity" @change="showPersonnel">
                <option v-for="activity in activites" :key="activity.id" :value="activity.details.activity_name">{{ activity.details.activity_name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="name">Fonction</label>
            <select class="form-control" v-model="personnel.function" @change="setCompetence">
                <option v-for="fonction in functions" :key="fonction.id" >{{ fonction.title }}</option>
            </select>
            <div v-if="competences" class="mt-2">
                <span class=" text-success m-2" v-for="competence in competences" :key="competence">{{ competence + ' ' }}</span>
            </div>
            <span v-if="personnel.function" style="cursor: pointer;" class="mx-2 text-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                <i class="fa fa-plus"></i>
            </span>
        </div>
        <div class="form-group">
            <label for="rto">Nombre de personnes pour la fonction</label>
            <input type="number" class="form-control" v-model="personnel.number">
        </div>
        <div class="form-group">
            <label for="rpo">Priorite de restauration</label>
            <select class="form-control" v-model="personnel.priority">
                <option v-for="priority in priorities" :key="priority.name" :value="priority.name">{{ priority.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="mtpd">RTO de fonction</label>
            <select class="form-control" v-model="personnel.rto">
                <option v-for="duration in durations" :key="duration.id" :value="duration.name">{{ duration.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="mtpd">Point de defaillance unique (SPOF)?</label>
            <select class="form-control" v-model="personnel.spof">
                <option value="oui">Oui</option>
                <option value="non">Non</option>
            </select>
        </div>
        <div class="form-group">
            <label for="comment">Commentaires</label>
            <textarea v-model="personnel.comment" id="" cols="30" rows="10" class="form-control"></textarea>
        </div>
        <button @click="savePersonnel" class="btn btn-success">Enregistrer</button>
    </div>
  </div>
</div>

    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Ajouter une competence</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label for="nom">Nom</label>
                <input type="text" v-model="competence.nom" class="form-control">
            </div>
            <div class="form-group">
                <label for="description">Description</label>
                <textarea v-model="competence.description" cols="30" rows="10" class="form-control"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
            <button @click="saveCompetence" class="btn btn-success" type="button">Enregistrer</button>
        </div>
        </div>
    </div>
    </div>

    </div>
</template>
<script>
import Choices from 'choices.js';
import API from './api'

    export default{
        data(){
            return{
                activites:[],
                entities: [],
                processes: [],
                priorities:[{name:'Faible'}, {name:'Moyen'}, {name:'Eleve'}],
                durations:[],
                personnels:[],
                functions:[],
                activity_personnels:[],
                personnel: {},
                activity_name:null,
                process_name: null,
                activity_rto:null,
                showForm:null,
                type: null,
                operationnel_id: null,
                competences: null,
                competence: {}
            }
        },
        created(){
            if (this.$route.params.type == 'orga') {
                this.onGetEntities(this.$route.params.id)
            }
            if (this.$route.params.type == 'process') {
                this.onGetProcesses(this.$route.params.id)
            }
        },
        mounted(){
            this.onGetDurations(),
            this.getActivities(),
            this.onGetPersonnels(this.$route.params.type, this.$route.params.id),
            this.onGetFunctions()
        },
        updated(){
            const selectElement = document.querySelector('.my-select');
            
            if(selectElement){
                new Choices(selectElement);
                selectElement.removeEventListener('change', this.myCallBack)
                selectElement.addEventListener('change', this.myCallBack);
            }
            
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async getActivities(){
                await API.getAllOperationnels().then(res =>{
                    if(res.data){
                        this.activites = this.$route.params.type == 'process' ? res.data.data.filter(obj => {return obj.universe.process_id == this.$route.params.id}) : res.data.data.filter(obj => {return obj.universe.entity_id == this.$route.params.id})
                    }
                })
            },
            async onGetProcesses(id){
                await API.getProcess(id).then(res => {
                    if(res.data.data){
                        this.process_name = res.data.data.name
                        this.processes.push(res.data.data)                        
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetEntities(id){
                await API.getEntities(id).then(res => {
                    if(res.data.data){
                        this.entities.push(res.data.data)
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async myCallBack(e){
                const selectedValue = e.target.value;
                if(selectedValue != 'all'){

                        await this.getActivities()
                        let theActivities = []
                        this.activites.forEach(element => {
                            if(this.entities.length > 0){
                                if(element.activity.entity_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                            if(this.processes.length > 0){
                                if(element.activity.process_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                        });
                        this.activites = theActivities
                    }else{
                        await this.getActivities()
                    }
            },
            async onGetDurations(){
                await API.getDrations().then(res =>{
                    if(res.data){
                        this.durations = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetFunctions(){
                await API.getFunctions().then(res =>{
                    if(res.data){
                        this.functions = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetPersonnels(type, id){
                let myParams = {}
                myParams.type = type
                myParams.id = id
                await API.getPersonnels(myParams).then(res =>{
                    if(res.data){
                        this.personnels = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            setShowForm(personnel){
                this.personnel = personnel   
            },
            showPersonnel(){
                const myTempData = this.activites.find(obj => {return obj.details.activity_name == this.personnel.activity})                
                this.operationnel_id = myTempData.id
            },
            async savePersonnel(){
                if(!this.personnel.id){
                    this.$route.params.type == 'process' ? this.personnel.process_id = parseInt(this.$route.params.id) : this.personnel.entity_id = parseInt(this.$route.params.id)
                    this.personnel.operationnel_id = this.operationnel_id
                }
                
                await API.createPersonnel(this.personnel).then(res =>{
                    if(res.data){
                        this.personnel = {}
                        this.onGetPersonnels()
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            updatePersonnel(personnel){
                this.personnel = personnel
                this.showForm = true
            },
            setCompetence(e){
                let myFunction = this.functions.find(obj => {return obj.title == e.target.value})
                this.competence.workplace_id = myFunction.id
                this.competences = myFunction.competences
            },
            async saveCompetence(){
                await API.addCompetence(this.competence).then(res =>{
                    if (res.data.data) {
                        this.competences = res.data.data.competences
                        this.competence.nom = ''
                        this.competence.description = ''
                    }
                }).catch(err => {
                    console.log(err);
                })
            },

        }
    }
</script>
<style>
    .breadcrumb-nav{
        width: fit-content;
    }
    .offcanvas-header .btn-close{
        color: white;
        background-color: green;
    }
    .my-cursor{
        cursor: pointer;
    }
</style>