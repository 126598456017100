<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                <ol class="breadcrumb">
                    <li @click="goTo('/organisme')" class="breadcrumb-item"><a href="#">Organisme</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Processus</li>
                </ol>
            </nav>
            <div class="card">
                <div v-if="processuses" class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center">Nom</th>
                                <th class="text-center">Creation</th>
                                <th class="text-center">Entite</th>
                                <th class="text-center">Employes</th>
                                <th class="text-center">Activites</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="process in processuses" :key="process.id">
                                <td v-if="process.name" class="text-center">{{process.name}}</td>
                                <td v-else></td>
                                <td v-if="process.created_at" class="text-center">{{process.created_at}}</td>
                                <td v-else></td>
                                <td v-if="process.entities" class="text-center">{{process.entities.length}}</td>
                                <td v-else></td>
                                <td v-if="process.employees" class="text-center">{{ process.employees.length }}</td>
                                <td v-if="process.activities" class="text-center">{{ process.activities.length }}</td>
                                <td v-else></td>
                                <td class="td-actions text-center">
                                    <button @click="goTo('/process/'+ process.id)"  type="button" rel="tooltip" class="btn btn-success btn-icon btn-sm " data-original-title="" title="">
                                        <i class="ni ni-circle-08 pt-1"></i>
                                    </button>
                                    <!-- <button type="button" rel="tooltip" class="btn btn-info btn-icon btn-sm " data-original-title="" title="">
                                        <i class="ni ni-settings-gear-65 pt-1"></i>
                                    </button>
                                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="" title="">
                                        <i class="ni ni-fat-remove pt-1"></i>
                                    </button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import API from '../api'

export default{
    data(){
        return{
           processuses:[],
        }
    },
    mounted(){
        this.onGetProcessuses()
    },
    methods:{
        goTo(path){
            this.$router.push(path)
        },
        async onGetProcessuses(){
            await API.getProcessuses().then(res =>{
                if(res.data){
                    this.processuses = res.data.data;
                }
            }).catch(err => {
                console.log('error >>>>', err.response.data)
            })
        },
    }
}
</script>
<style scoped>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>