<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header pb-0">
                    <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                        <ol class="breadcrumb">
                            <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Grille d'evaluation</li>
                        </ol>
                    </nav>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                    <div class="table-responsive p-4" v-if="percentage == 100">
                        <table class="table align-items-center mb-0">
                        <thead v-if="sectors">
                            <tr style="background-color: aqua;">
                            <th
                                class="text-uppercase text-secondary font-weight-bolder opacity-7"
                            >
                                matrice d'impact
                            </th>
                            <th v-for="sector in sectors" :key="sector.id"
                                class="text-uppercase text-secondary font-weight-bolder opacity-7 ps-2"
                            >
                                {{ sector.name }} ({{ sector.percentage }}%)
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="impact in impacts" :key="impact.id" class="text-center">
                                <td style="background-color: lightgoldenrodyellow;">
                                    <p class=" font-weight-bold ">{{ impact.name }} ({{ impact.ponderation }})</p>
                                </td>
                                <td :style="{'background-color': impact.color, 'color':'white' }" v-for="sector in sectors" :key="sector.id">
                                    <div v-for="grid in grids" :key="grid.id">
                                        <p data-bs-toggle="modal" data-bs-target="#exampleModal" @click="showText(grid.note)" v-if="grid.sector.id == sector.id && grid.impact.id == impact.id"  class="text-xs font-weight-bold mb-0">{{grid.note ? breakSentence(grid.note) : ''}}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div v-else class="text-center text-danger"> La somme des impacts doit etre egal a 100</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                {{ modalText }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
        </div>


    </div>
</template>
<script>
// import ArgonAlert from "@/components/ArgonAlert.vue";
import API from './api'

export default {
    data() {
      return {
        grids: [],
        sectors: [],
        impacts: [],
        modalText:'',
        percentage:0,
      }
    },
    mounted(){
        this.onGetGrille(),
        this.onGetImpacts(),
        this.onGetSectors()
    },
    methods:{
           async onGetGrille(){
                await API.getGrille().then(res =>{
                    if(res.data){
                        this.grids = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
           async onGetImpacts(){
                await API.getImpacts().then(res =>{
                    if(res.data){
                        this.impacts = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
           async onGetSectors(){
                await API.getSectors().then(res =>{
                    if(res.data){
                        this.sectors = res.data.data;
                        this.sectors.forEach(element => {
                            this.percentage += element.percentage
                        });
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            breakSentence(text){
                if(text.length > 20) {
                    let result = text.slice(0, 20)
                    return result
                }
                return text
            },
            showText(text){
                this.modalText = text
                // opens the modal window
                // points to the id of the modal
            },
            goTo(path){
                this.$router.push(path)
            }
    }
  }
</script>
<style scoped>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>