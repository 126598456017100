import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Vue3FormWizard from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(Vue3FormWizard)
appInstance.use(VueSweetalert2)
if (localStorage.getItem('user') == null) router.push('/signin')
appInstance.mount("#app");
