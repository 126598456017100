<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Dependances externes</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Activites</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">RTO</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Dependances</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="activity in activites" :key="activity.id">
                                        <td class="text-xs font-weight-bold text-center">{{activity.details.activity_name}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{activity.rto}}</td>
                                        <td class="text-xs font-weight-bold text-center">
                                            <button @click="showData(activity)" class="btn btn-success" type="button">Voir <i class="ni ni-bold-right"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

<div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasScrollingLabel">{{ activity_name }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <button class="btn btn-success" @click="setShowForm">Ajouter une dependance</button>
    <div v-if="showForm">
        <div class="form-group">
            <label for="provider">Choisir un fournisseur / prestataire</label>
            <select class="form-control" v-model="externe.provider">
                <option v-for="provider in providers" :key="provider.id" :value="provider.name">{{ provider.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="comment">Service Fourni</label>
            <textarea v-model="externe.service" cols="30" rows="10" class="form-control"></textarea>
        </div>
        <div class="form-group">
            <label for="rto">RTO du fournisseur</label>
            <select class="form-control" v-model="externe.rto">
                <option v-for="duration in durations" :key="duration.id" :value="duration.name">{{ duration.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="setCheckbox">
                <label class="form-check-label" for="flexSwitchCheckChecked">Fournisseur / prestataire alternatif</label>
            </div>
        </div>
        <div class="form-group" v-if="setCheckbox">
            <label for="provider">Choisir un fournisseur / prestataire Alternatif</label>
            <select class="form-control" v-model="externe.alternative">
                <option v-for="provider in providers" :key="provider.id" :value="provider.name">{{ provider.name }}</option>
            </select>
        </div>
        <div class="form-group" v-if="setCheckbox">
            <label for="alter_percent">Pourcentage de l'approvisionnement actuel</label> 
            <input type="number" class="form-control" min="1" max="100" placeholder="Pourcentage" v-model="externe.alter_percent">
        </div>
        <div class="form-group" v-if="setCheckbox">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="isMcaExigence" v-model="externe.isMcaExigence">
                <label class="form-check-label" for="isMcaExigence">Est ce que le MCA est une exigence dans le contrat ou le SLA</label>
            </div>
        </div>
        <div v-if="setCheckbox">
            <div class="form-group" v-for="sect in sectors" :key="sect.id">
                <label :for="sect.name">Impact de l'arret sur: {{sect.name}}</label>
                <input type="number" class="form-control" v-model="sector[sect.name]">
            </div>
            <div class="form-group">
                <label for="total">Total</label>
                <input type="number" readonly class="form-control" v-model="externe.total">
            </div>
        </div>
        
        <button @click="saveExterne" class="btn btn-success">Enregistrer</button>
    </div>
    <h6 class="text-center">Liste des dependances</h6>
    <div class="table-responsive">
        <table class="table align-items-center">
            <thead>
                <tr>
                    <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Dependance</th>
                    <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">RTO</th>
                </tr>
            </thead>
            <tbody>
                <tr class="my-cursor" v-for="activity in activity_externes" :key="activity.id" @click="updateData(activity)">
                    <td class="text-xs font-weight-bold text-center">{{activity.dependance}}</td>
                    <td class="text-xs font-weight-bold text-center">{{activity.rto }}</td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
</div>

    </div>
</template>
<script>
import * as bootstrap from 'bootstrap';
import Choices from 'choices.js';
import API from './api';

    export default{
        data(){
            return{
                activites:[],
                entities: [],
                processes: [],
                durations:[],
                externes:[],
                providers:[],
                externe: {},
                sector: {},
                realActivity: {},
                realActivities: [],
                activity_externes: [],
                activity_name:null,
                activity_rto:null,
                showForm:null,
                type: null,
                operationnel_id:null,
                setCheckbox:null,
                sectors:[],
            }
        },
        created(){
            if (this.$route.params.type == 'orga') {
                this.onGetEntities(this.$route.params.id)
            }
            if (this.$route.params.type == 'process') {
                this.onGetProcesses(this.$route.params.id)
            }
        },
        mounted(){
            this.onGetDurations(),
            this.onGetSectors(),
            this.getActivities(),
            this.onGetExternes(this.$route.params.type, this.$route.params.id),
            this.onGetProviders(),
            this.getRealActivities()
        },
        updated(){
            const selectElement = document.querySelector('.my-select');
            
            if(selectElement){
                new Choices(selectElement);
                selectElement.removeEventListener('change', this.myCallBack)
                selectElement.addEventListener('change', this.myCallBack);
            }
            let tempArray = []
            this.sectors.forEach(el => {
                if(this.sector[el.name]){
                    tempArray.push(this.sector[el.name])
                }
            });
            this.externe.total = tempArray.reduce((a, b) => a + b, 0)            
            
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async getActivities(){
                await API.getAllOperationnels().then(res =>{
                    if(res.data){
                        this.activites = this.$route.params.type == 'process' ? res.data.data.filter(obj => {return obj.universe.process_id == this.$route.params.id}) : res.data.data.filter(obj => {return obj.universe.entity_id == this.$route.params.id})
                    }
                })
            },
            async getActivity(activity_id){
                await API.getActivity(activity_id).then(res =>{
                    if(res.data){
                        this.realActivity = res.data.data
                    }
                })
            },
            async getRealActivities(){
                await API.getActivities().then(res =>{
                    if(res.data){
                        this.realActivities = res.data.data
                    }
                })
            },
            async onGetProcesses(id){
                await API.getProcess(id).then(res => {
                    if(res.data.data){
                        this.processes = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetEntities(id){
                await API.getEntity(id).then(res => {
                    if(res.data.data){
                        this.entities = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetProviders(){
                await API.getProviders().then(res => {
                    if(res.data.data){
                        this.providers = res.data.data
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async myCallBack(e){
                const selectedValue = e.target.value;
                if(selectedValue != 'all'){

                        await this.getActivities()
                        let theActivities = []
                        this.activites.forEach(element => {
                            if(this.entities.length > 0){
                                if(element.activity.entity_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                            if(this.processes.length > 0){
                                if(element.activity.process_id == selectedValue){
                                    theActivities.push(element)
                                }
                            }
                        });
                        this.activites = theActivities
                    }else{
                        await this.getActivities()
                    }
            },
            async onGetDurations(){
                await API.getDrations().then(res =>{
                    if(res.data){
                        this.durations = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetSectors(){
                await API.getSectors().then(res =>{
                    if(res.data){
                        this.sectors = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetExternes(type, id){
                let myParams = {}
                myParams.type = type
                myParams.id = id
                await API.getExternes(myParams).then(res =>{
                    if(res.data){
                        this.externes = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            setShowForm(){
                this.showForm = !this.showForm
                this.data = {}
            },
            async showData(activity){
                await this.getActivity(activity.universe.activity_id)
                this.activity_name = activity.details.activity_name
                this.operationnel_id = activity.id
                
                var myOffcanvas = document.getElementById('offcanvasScrolling')
                var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
                                  bsOffcanvas.show()

                this.activity_externes = this.externes.filter(obj => {return obj.operationnel_id == activity.id})
            },
            async saveExterne(){
                if(!this.externe.id){
                    this.$route.params.type == 'process' ? this.externe.process_id = parseInt(this.$route.params.id) : this.externe.entity_id = parseInt(this.$route.params.id)
                    this.externe.operationnel_id = this.operationnel_id
                }
                this.externe.sectors = JSON.stringify(this.sector)
                
                await API.createExterne(this.externe).then(res =>{
                    if(res.data){                        
                        this.externes.push(res.data);
                        this.externe = {}
                        this.sector = {}
                        this.showForm = false
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            updateData(data){
                this.externe = data
                this.showForm = true
            },
            showActivityName(data){
               const tempActivity = this.activites.find(obj => {return obj.id == data.operationnel_id})
                return tempActivity.details.activity_name
            },
            getInputs(activity_id){
                console.log(activity_id);
            },

        }
    }
</script>
<style>
    .breadcrumb-nav{
        width: fit-content;
    }
    .offcanvas-header .btn-close{
        color: white;
        background-color: green;
    }
    .my-cursor{
        cursor: pointer;
    }
</style>