<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                <ol class="breadcrumb">
                    <li @click="goTo('/organisme')" class="breadcrumb-item"><a href="#">Organisme</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Environement (I & E)</li>
                </ol>
            </nav>
            <h1 class="text-white text-center">Creation de l'Environement (I & E)</h1>
            

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header text-center">
                        <div class="form-check form-check-inline" @click="setSwot">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                            <label class="form-check-label" for="inlineRadio1">Analyse SWOT</label>
                        </div>
                            <div class="form-check form-check-inline" @click="setPestel">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                            <label class="form-check-label" for="inlineRadio2">Analyse Pestel</label>
                            </div>
                    </div>
                        <form-wizard color="#094899" class="px-4 card-body" v-if="type == 0" @on-complete="onCompleteSwot" step-size="xs"
                        title="This is a new title"
                        subtitle="And a new subtitle"
                        shape="tab"
                        back-button-text="< Precedent"
                        next-button-text="Suivant >"
                        finish-button-text="Enregistrer"
                        >
                            <tab-content title="Forces" icon="fa fa-user">
                                <div class="form-floating">
                                    <textarea v-model="analyze.strengths" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Forces</label>
                                </div>
                            </tab-content>
                            <tab-content title="Faiblesses" icon="fa fa-gear">
                                <div class="form-floating">
                                    <textarea v-model="analyze.weaknesses" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Faiblesses</label>
                                </div>
                            </tab-content>
                            <tab-content title="Opportunites" icon="fa fa-city">
                                <div class="form-floating">
                                    <textarea v-model="analyze.opportunities" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Opportunites</label>
                                </div>
                            </tab-content>
                            <tab-content title="Menaces" icon="fa fa-bolt">
                                <div class="form-floating">
                                    <textarea v-model="analyze.threats" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Menaces</label>
                                </div>
                            </tab-content>
                        </form-wizard>
                        <form-wizard shape="tab" color="#c11c67" class="px-4 card-body" v-if="type == 1" @on-complete="onCompletePestel" step-size="xs"
                        title="This is a new title"
                        subtitle="And a new subtitle"
                        back-button-text="< Precedent"
                        next-button-text="Suivant >"
                        finish-button-text="Enregistrer"
                        >
                        <tab-content title="Politique" icon="fa fa-user">
                                <div class="form-floating">
                                    <textarea v-model="analyze.politic" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Politique</label>
                                </div>
                            </tab-content>
                            <tab-content title="Economique" icon="fa fa-gear">
                                <div class="form-floating">
                                    <textarea v-model="analyze.economic" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Economic</label>
                                </div>
                            </tab-content>
                            <tab-content title="Social" icon="fa fa-city">
                                <div class="form-floating">
                                    <textarea v-model="analyze.social" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Social</label>
                                </div>
                            </tab-content>
                            <tab-content title="Technologique" icon="fa fa-bolt">
                                <div class="form-floating">
                                    <textarea v-model="analyze.technologic" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Technologique</label>
                                </div>
                            </tab-content>
                        </form-wizard>
            </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from '../api'

export default{
    components:{
    },
    data(){
        return{
           analyze:{
            strengths:'',
            weaknesses:'',
            opportunities:'',
            threats:'',
            politic:'',
            economic:'',
            social:'',
            technologic:'',
           },
           id:null,
           type:0,
        }
    },
    mounted(){
        this.type = 0
        if(this.$route.params.id){
            this.id = this.$route.params.id
            console.log(this.id);
            this.onGetProcess(this.id)
        }
    },
    methods:{
        goTo(path){
            this.$router.push(path)
        },
        async onGetProcess(id){
            await API.getProcess(id).then(res =>{
                if(res.data){
                    this.process = res.data.data;
                }
            }).catch(err => {
                console.log('error >>>>', err.response.data)
            })
        },
        setSwot(){
            this.type = 0
        },
        setPestel(){
            this.type = 1
        },
        async onCompletePestel(){
            if(
                this.analyze.politic == '' || this.analyze.economic == '' || 
                this.analyze.social == '' || this.analyze.technologic == ''
            ){
                console.log(this.analyze);
               return this.$swal(
                  'ATTENTION!',
                  'Veuillez remplir tous les champs.',
                  'Danger'
                )
            }
            this.$swal({
              title: 'Etes-vous Sure?',
              text: "Cette analyse Pestel sera sauvegarder!",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d13737',
              confirmButtonText: 'Oui',
              cancelButtonText: 'Annuler'
            }).then((result) => {
              if (result.value) {
                API.createEnvironment(this.analyze).then(res =>{
                        if(res.data){
                            this.analyze = res.data.data;
                            this.$swal(
                            'Enregistrer!',
                            'Votre analyse a ete sauvegardee.',
                            'Success'
                            )
                        }
                        }).catch(err => {
                            console.log('error >>>>', err.response.data)
                        })
              }
            });
        },
        onCompleteSwot(){
            if(
                this.analyze.strengths == '' || this.analyze.weaknesses == '' || 
                this.analyze.opportunities == '' || this.analyze.threats == ''
            ){
                console.log(this.analyze);
               return this.$swal(
                  'ATTENTION!',
                  'Veuillez remplir tous les champs.',
                  'Danger'
                )
            }
            this.$swal({
              title: 'Etes-vous Sure?',
              text: "Cette analyse SWOT sera sauvegarder!",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d13737',
              confirmButtonText: 'Oui',
              cancelButtonText: 'Annuler'
            }).then((result) => {
              if (result.value) {
                API.createEnvironment(this.analyze).then(res =>{
                        if(res.data){
                            this.analyze = res.data.data;
                            this.$swal(
                            'Enregistrer!',
                            'Votre analyse a ete sauvegardee.',
                            'Success'
                            )
                        }
                        }).catch(err => {
                            console.log('error >>>>', err.response.data)
                        })
              }
            });
        },
    }
}
</script>
<style scoped>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>