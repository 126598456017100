<template>
  <div class="card">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0"> les employes de l'activite</h6>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group" v-if="employees">
        <li v-for="employee in employees" :key="employee.id"
          class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">{{employee.name}}</h6>
            <span class="mb-2 text-xs">
              Poste:
              <span class="text-dark font-weight-bold ms-sm-2"
                >{{employee.poste}}</span
              >
            </span>
            <span class="mb-2 text-xs">
              Entite:
              <span class="text-dark ms-sm-2 font-weight-bold"
                >{{activity.entity.name}}</span
              >
            </span>
            <span class="text-xs">
              Date de creation:
              <span class="text-dark ms-sm-2 font-weight-bold">{{setDate(employee.created_at )}}</span>
            </span>
          </div>
          <div class="ms-auto text-end">
            <!-- <a
              class="btn btn-link text-danger text-gradient px-3 mb-0"
              href="javascript:;"
            >
              <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
            </a> -->
            <a @click="goTo('/employee/' + employee.id)" class="btn btn-link text-success px-3 mb-0" href="javascript:;">
              <i class="fas fa-eye text-success me-2" aria-hidden="true"></i
              >Voir
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
    export default {
      props: {
        employees:Array,
        activity:Object,
      },
      data(){
        return{

        }
      },
      mounted(){
        
      },
      methods:{
        setDate(date){
          let stringDate = new Date(date)
          return stringDate.getDate() + '/' + this.getNameMonth(stringDate.getMonth()) + '/' + stringDate.getFullYear() + ' a ' +stringDate.getUTCHours() + 'H ' + stringDate.getUTCMinutes()
        },
        getNameMonth(month){
          let monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"]
          return monthNames[month]
        },
        goTo(path){
            this.$router.push(path)
        },
      }
    }
</script>
