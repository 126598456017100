<template>
    <div class="cointainer-fluid">
        <div class="card">
            <div class="card-header pb-0">
                <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                    <ol class="breadcrumb">
                        <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Univers des activites</li>
                    </ol>
                </nav>
            </div>
            <div class="row card-body">
            <div @click="goTo('/univers-activites/orga')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-ungroup"></i>
                <div class="bia-text">Par Organigramme</div>
            </div>
            <div @click="goTo('/univers-activites/process')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-single-copy-04"></i>
                <div class="bia-text">Par Processus</div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
    
    export default{
        data(){
            return{

            }
        },
        mounted(){

        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            
        }
    }
</script>
<style scoped>
    .bia-box{
        width: 500px;
        padding: 50px;
        margin: 20px;
        cursor: pointer;
    }
    .ni{
        font-size: 100px;
        font-weight: bolder;
        margin-bottom: 10px;
    }
    .bia-text{
        font-weight: bold;
        font-size: 25px;
    }
</style>