<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                <ol class="breadcrumb">
                    <li @click="goTo('/organisme')" class="breadcrumb-item"><a href="#">Organisme</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Activites</li>
                </ol>
            </nav>
            <div class="card">
                <div v-if="activities" class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center">Nom</th>
                                <th class="text-center">Site</th>
                                <th class="text-center">Entite</th>
                                <th class="text-center">Employes</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="activity in activities" :key="activity.id">
                                <td v-if="activity.name" class="text-center">{{activity.name}}</td>
                                <td v-else></td>
                                <td v-if="activity.site" class="text-center">{{activity.site}}</td>
                                <td v-else></td>
                                <td v-if="activity.entity" class="text-center">{{activity.entity.name}}</td>
                                <td v-else></td>
                                <td v-if="activity.employees" class="text-center">{{ activity.employees.length }}</td>
                                <td v-else></td>
                                <td class="td-actions text-center">
                                    <button @click="goTo('/activity/'+ activity.id)"  type="button" rel="tooltip" class="btn btn-success btn-icon btn-sm " data-original-title="" title="">
                                        <i class="ni ni-circle-08 pt-1"></i>
                                    </button>
                                    <!-- <button type="button" rel="tooltip" class="btn btn-info btn-icon btn-sm " data-original-title="" title="">
                                        <i class="ni ni-settings-gear-65 pt-1"></i>
                                    </button>
                                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="" title="">
                                        <i class="ni ni-fat-remove pt-1"></i>
                                    </button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import API from '../api'

export default{
    data(){
        return{
           activities:[],
        }
    },
    mounted(){
        this.onGetActivities()
    },
    methods:{
        goTo(path){
            this.$router.push(path)
        },
        async onGetActivities(){
            await API.getActivities().then(res =>{
                if(res.data){
                    this.activities = res.data.data;
                }
            }).catch(err => {
                console.log('error >>>>', err.response.data)
            })
        },
    }
}
</script>
<style scoped>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>