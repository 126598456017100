<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                <ol class="breadcrumb">
                    <li @click="goTo('/organisme')" class="breadcrumb-item"><a href="#">Organisme</a></li>
                    <li v-if="activity" class="breadcrumb-item active" aria-current="page">{{activity.name}}</li>
                </ol>
            </nav>
            <h1 v-if="activity" class="text-white text-center">{{ activity.name }}</h1>
            

            <div class="row">
                <div class="col-md-7">
                    <employees-card :employees="activity.employees" :activity="activity" />
                </div>
                <div class="col-md-5">
                    <entity-card :activity="activity" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EmployeesCard from "../components/EmployeesCard.vue";
import EntityCard from "../components/EntityCard.vue";
import API from './api'

export default{
    components:{
        EmployeesCard,
        EntityCard
    },
    data(){
        return{
           activity:{},
           id:null,
        }
    },
    mounted(){
        if(this.$route.params.id){
            this.id = this.$route.params.id
            this.onGetActivity(this.id)
        }
    },
    methods:{
        goTo(path){
            this.$router.push(path)
        },
        async onGetActivity(id){
            await API.getActivity(id).then(res =>{
                if(res.data){
                    this.activity = res.data.data;
                }
            }).catch(err => {
                console.log('error >>>>', err.response.data)
            })
        },
    }
}
</script>
<style scoped>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>