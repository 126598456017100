<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <h1 class="text-white text-center">Les phase d'un projet SMCA</h1>
            <div v-for="phase in phases" :key="phase.id" @click="goTo('/structure/phase/' + phase.id)"  class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <div class="bia-text">{{phase.name}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import API from './api'

    export default{
        data(){
            return{
                phases:[],
            }
        },
        mounted(){
            this.onGetPhases()
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async onGetPhases(){
                await API.getPhases().then(res =>{
                    if(res.data){
                        this.phases = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
        }
    }
</script>
<style scoped>
    .bia-box{
        width: 500px;
        padding: 50px;
        margin: 20px;
        cursor: pointer;
    }
    .bia-text{
        font-weight: bold;
        font-size: 25px;
    }
</style>