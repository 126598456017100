<template>
    <div class="container-fluid">
        <div class="row">
            <div @click="goTo('/activites')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-settings"></i>
                <div class="bia-text">Activites</div>
            </div>
            <div @click="goTo('/processus')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-map-big"></i>
                <div class="bia-text">Processus</div>
            </div>
            <div @click="goTo('/environments')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-planet"></i>
                <div class="bia-text">Environement Interne et Externe</div>
            </div>
            <div @click="goTo('/exigences-metier')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-controller"></i>
                <div class="bia-text">Exigences Metier</div>
            </div>
            <div @click="goTo('/analyse-ecarts')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-align-left-2"></i>
                <div class="bia-text">Analyse des ecarts</div>
            </div>
            
        </div>
    </div>
</template>
<script>
    
    export default{
        data(){
            return{

            }
        },
        mounted(){

        },
        methods:{
            goTo(path){
                this.$router.push(path)
            }
        }
    }
</script>
<style scoped>
    .bia-box{
        width: 500px;
        padding: 50px;
        margin: 20px;
        cursor: pointer;
    }
    .ni{
        font-size: 100px;
        font-weight: bolder;
        margin-bottom: 10px;
    }
    .bia-text{
        font-weight: bold;
        font-size: 25px;
    }
</style>