<template>
    <div class="py-4 container-fluid">
        <div class="row">
                <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                    <ol class="breadcrumb">
                        <li @click="goTo('/structure')" class="breadcrumb-item"><a href="#">Structure</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ phase.name }}</li>
                    </ol>
                </nav>
            <h1 v-if="phase" class="text-white text-center">Les differentes etapes de la phase {{ phase.name }}</h1>
            <div v-for="etape in etapes" :key="etape.id" @click="goTo('/structure/etape/' + etape.id)"  class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <div class="bia-text">{{etape.name}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import API from './api'

    export default{
        data(){
            return{
                etapes:[],
                phase_id:'',
                phase:{},
            }
        },
        mounted(){
            if(this.$route.params.id){
                this.phase_id = this.$route.params.id
                this.onGetEtapes(this.phase_id)
            }
            
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async onGetEtapes(id){
                await API.getEtapes(id).then(res =>{
                    if(res.data){
                        this.etapes = res.data.data;
                        this.phase = res.data.phase
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
        }
    }
</script>
<style scoped>
    .bia-box{
        width: 500px;
        padding: 50px;
        margin: 20px;
        cursor: pointer;
    }
    .bia-text{
        font-weight: bold;
        font-size: 25px;
    }
    .breadcrumb-nav{
        width: fit-content;
    }
</style>