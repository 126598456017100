<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/bia-informatique')" class="breadcrumb-item"><a href="#">bia Informatique</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Serveurs</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Nom</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Fonction</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Service</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">RTO</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">RPO</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">MTPD</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Activite</th>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="systeme in systemes" :key="systeme.id">
                                        <td class="text-xs font-weight-bold text-center">{{systeme.name}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{systeme.comment}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{''}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{systeme.rto}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{systeme.rpo}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{systeme.mtpd}}</td>
                                        <td class="text-xs font-weight-bold text-center">{{''}}</td>
                                        <td class="text-xs font-weight-bold text-center">
                                            <button @click="showData(systeme)" class="btn btn-success" type="button">Voir <i class="ni ni-bold-right"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasScrollingLabel">Test name</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div v-if="questions.length">
                    <div class="form-group" v-for="question in questions" :key="question.id">
                        <label :for="question.id">{{ question.title }}</label>
                        <input type="number" class="form-control" v-model="questiones[question.id]">
                    </div>
                    <div class="form-group">
                        <label for="total">Duree total en heure</label>
                        <input type="number" class="form-control" readonly v-model="total">
                    </div>
                    <button class="btn btn-success" @click="saveQuestion">Enregistrer</button>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import * as bootstrap from 'bootstrap';
import API from './api';

    export default {
        data(){
            return{
                systemes:[],
                questiones:{},
                questions:[],
                systeme:{},
                total:null,
                questionId:null,
            }
        },
        updated(){
            this.setTotal()
        },
        mounted(){
            this.onGetSystemesByType('server'),
            this.onGetSystemesQuestions('serveur')
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async onGetSystemesByType(type){
                const myParam = {}
                myParam.type = type
                await API.getSystemesByType(myParam).then(res =>{
                    if(res.data){
                        this.systemes = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetSystemesQuestions(type){
                const myParam = {}
                myParam.type = type
                await API.getSystemesQuestions(myParam).then(res =>{
                    if(res.data){
                        this.questions = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async onGetSavedQuestion(id){
                this.questiones = {}
                const myParam = {}
                myParam.id = id
                await API.getSavedQuestion(myParam).then(res =>{
                    if(res.data.data.length){  
                        this.questionId = res.data.data[0].id                      
                        res.data.data[0].server ? this.questiones = JSON.parse(res.data.data[0].server) : {};
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
            async showData(systeme){                
                this.systeme = systeme
                var myOffcanvas = document.getElementById('offcanvasScrolling')
                var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
                                  bsOffcanvas.show()
                this.onGetSavedQuestion(systeme.id)
            },
            setTotal(){
                let tempArray = []
                for (const [key, value] of Object.entries(this.questiones)) {
                    tempArray.push(value)
                }
                
                this.total = tempArray.reduce((a, b) => a + b, 0)
            },
            async saveQuestion(){
                const myParam = {}
                myParam.system_id = this.systeme.id
                myParam.id = this.questionId
                myParam.type = 'server'
                myParam.total = this.total
                myParam.questions = JSON.stringify(this.questiones)
                await API.saveQuestion(myParam).then(res =>{
                    if(res.data){
                        console.log('ok');
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
        },
    }
</script>

<style scoped>

</style>