<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                            <ol class="breadcrumb">
                                <li @click="goTo('/bia')" class="breadcrumb-item"><a href="#">BIA</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Recapitulatif RTO</li>
                            </ol>
                        </nav>
                    </div>
                    
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table align-items-center">
                                <thead>
                                    <tr>
                                        <th style="background-color: #344767;" class="text-uppercase text-xs text-white font-weight-bolder text-center">Nom de l'activite</th>
                                        <th style="background-color: #344767;" v-for="duration in durations" :key="duration.id" class="text-uppercase text-xs text-white font-weight-bolder text-center">{{ duration.name }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="activity in operationnels" :key="activity.id">
                                        <td class="text-xs font-weight-bold text-center">{{activity.details.activity_name}}</td>
                                        <td v-for="duration in durations" :key="duration.id" class="text-xs font-weight-bold text-center"><input type="text" readonly :id="(activity.details.activity_name+'-'+duration.name).replace(/\s/g, '')" :style="activity.rto == duration.name ? 'background-color:red' : ''" class="form-control"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from './api'

    export default{
        data(){
            return{
                activites:[],
                operationnels:[],
                durations:[],
                type: null,
                durationData: {}
            }
        },
        created(){
            this.onGetDurations()
        },
        mounted(){
            this.getActivities(this.$route.params.type, this.$route.params.id),
            this.getOperationnels()
        },
        updated(){
            
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            async getActivities(type, id){
                let myParam = {}
                myParam.type = type
                type == 'process' ? myParam.process_id = id : myParam.entity_id = id
                await API.getCritiqActivities(myParam).then(res =>{
                    if(res.data){
                        this.activites = res.data.data
                    }
                })
            },
            async getOperationnels(){
                await API.getAllOperationnels().then(res =>{
                    if(res.data){
                        this.operationnels = this.$route.params.type == 'process' ? res.data.data.filter(obj => {return obj.universe.process_id == this.$route.params.id}) : res.data.data.filter(obj => {return obj.universe.entity_id == this.$route.params.id})
                         res.data.data.forEach(el => {
                                 this.durations.forEach(dur =>{
                                    let tempVale = (el.details.activity_name+'-'+dur.name).replace(/\s/g, '')
                                    console.log(document.getElementById(tempVale), tempVale);
                                    // document.getElementById(el.details.activity_name+'/'+dur.name).style.backgroundColor = 'yellow'
                                 })
                        });
                    }
                })
            },
            async onGetDurations(){
                await API.getDrations().then(res =>{
                    if(res.data){
                        this.durations = res.data.data;
                    }
                }).catch(err => {
                    console.log('error >>>>', err.response.data)
                })
            },
        }
    }
</script>
<style>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>