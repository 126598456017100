import axios from "axios"
import { BASE_URL } from "../../server/Request"

export default {
    getActivities: (params = {}, headers = {}) => axios.get(BASE_URL + "activity", params, headers),
    getEmployees: (params = {}, headers = {}) => axios.get(BASE_URL + "employees", params, headers),
    getProcessuses: (params = {}, headers = {}) => axios.get(BASE_URL + "processuses", params, headers),
    getEnvironments: (params = {}, headers = {}) => axios.get(BASE_URL + "environments", params, headers),
    createEnvironment: (params = {}, headers = {}) => axios.post(BASE_URL + "environment-create", params, headers),
    getActivity: (id) => axios.get(BASE_URL + "activity/" + id),
    getEmployee: (id) => axios.get(BASE_URL + "employee/" + id),
    getProcess: (id) => axios.get(BASE_URL + "process/" + id),
    getEnvironment: (id) => axios.get(BASE_URL + "environment/" + id),
}