<template>
    <div class="container-fluid">
        <div class="row">
            <div @click="goTo('/bia-infra')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-bullet-list-67"></i>
                <div class="bia-text">Infrastructure</div>
            </div>
            <div @click="goTo('/bia-server')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-laptop"></i>
                <div class="bia-text">Serveurs</div>
            </div>
            <div @click="goTo('/bia-data')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-money-coins"></i>
                <div class="bia-text">Donnees</div>
            </div>
        </div>
    </div>
</template>
<script>
    
    export default{
        data(){
            return{
                path: null,
            }
        },
        mounted(){
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
        }
    }
</script>
<style scoped>
     .bia-box{
        width: 500px;
        padding: 50px;
        margin: 20px;
        cursor: pointer;
    }
    .ni{
        font-size: 100px;
        font-weight: bolder;
        margin-bottom: 10px;
    }
    .bia-text{
        font-weight: bold;
        font-size: 25px;
    }
</style>