<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <nav aria-label="breadcrumb" role="navigation" class="breadcrumb-nav">
                <ol class="breadcrumb">
                    <li @click="goTo('/organisme')" class="breadcrumb-item"><a href="#">Organisme</a></li>
                    <li v-if="process" class="breadcrumb-item active" aria-current="page">{{process.name}}</li>
                </ol>
            </nav>
            <h1 v-if="process" class="text-white text-center">{{ process.name }}</h1>
            

            <div class="row">
                <div class="col-md-7">
                    
                </div>
                <div class="col-md-5">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from '../api'

export default{
    components:{
    },
    data(){
        return{
           process:{},
           id:null,
        }
    },
    mounted(){
        if(this.$route.params.id){
            this.id = this.$route.params.id
            console.log(this.id);
            this.onGetProcess(this.id)
        }
    },
    methods:{
        goTo(path){
            this.$router.push(path)
        },
        async onGetProcess(id){
            await API.getProcess(id).then(res =>{
                if(res.data){
                    this.process = res.data.data;
                }
            }).catch(err => {
                console.log('error >>>>', err.response.data)
            })
        },
    }
}
</script>
<style scoped>
    .breadcrumb-nav{
        width: fit-content;
    }
</style>