import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import BIA from "../views/BIA/index.vue";
import Structure from "../views/Structure/index.vue";
import Phase from "../views/Structure/Phase.vue";
import Etape from "../views/Structure/Etape.vue";
import Organisme from "../views/Organisme/index.vue";
import Activites from "../views/Organisme/Activites.vue";
import Processuses from "../views/Organisme/Process/Processuses.vue";
import Process from "../views/Organisme/Process/Process.vue";
import Activity from "../views/Organisme/Activity.vue";
import Grille from "../views/BIA/Grille.vue";
import UniverseTypes from "../views/BIA/UniverseTypes.vue";
import Univers from "../views/BIA/Univers.vue";
import Critiques from "../views/BIA/Critiques";
import Organisationnel from "../views/BIA/Organisationnel.vue";
import RecapRto from "../views/BIA/Recaprto.vue";
import DetailsActivites from "../views/BIA/DetailsActivites.vue";
import Systemes from "../views/BIA/Systemes.vue";
import Personnels from "../views/BIA/Personnel.vue";
import Espace from "../views/BIA/Espace.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Employee from "../views/Organisme/Employee/Employee.vue";
import Employees from "../views/Organisme/Employee/Employees.vue";
import Environments from "../views/Organisme/Environment/index.vue";
import Environment from "../views/Organisme/Environment/Environment.vue";
import CreateEnvironment from "../views/Organisme/Environment/Create.vue";
import Donnees from "../views/BIA/Donnees.vue";
import Internes from "../views/BIA/Internes.vue";
import Externes from "../views/BIA/Externes.vue";
import BiaInformatique from '../views/informatique/index.vue'
import RapportGeneral from '../views/rapports/index.vue'
import BiaInfra from '../views/informatique/Infra.vue'
import BiaServer from '../views/informatique/server.vue'
import BiaData from '../views/informatique/data.vue'

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/structure",
  },

  //Routes Structure SMCA
  {
    path:"/structure",
    name:"structure",
    component:Structure,
  },
  {
    path:"/structure/phase/:id",
    name:"phase",
    component:Phase,
  },
  {
    path:"/structure/etape/:id",
    name:"etape",
    component:Etape,
  },
  {
    path:"/organisme",
    name:"organisme",
    component:Organisme,
  },
  {
    path:"/activites",
    name:"activites",
    component:Activites,
  },
  {
    path:"/activity/:id",
    name:"activity",
    component:Activity,
  },
  {
    path:"/employees",
    name:"employees",
    component:Employees,
  },
  {
    path:"/employee/:id",
    name:"employee",
    component:Employee,
  },
  {
    path:"/processus",
    name:"processus",
    component:Processuses,
  },
  {
    path:"/process/:id",
    name:"process",
    component:Process,
  },

  //Routes environnement interne et externe
  {
    path:"/environments",
    name:"environments",
    component:Environments
  },
  {
    path:"/environment/:id",
    name:"environment",
    component:Environment
  },
  {
    path:"/create-environment",
    name:"environment-create",
    component:CreateEnvironment
  },

  // Route du BIA
  {
    path:"/bia",
    name:"bia",
    component: BIA,
  },
  {
    path:"/univers-activites/:type/:id",
    name:"univers-activites",
    component: Univers,
  },
  {
    path:"/activites-critiques/:type/:id",
    name:"activites-critiques",
    component: Critiques,
  },
  {
    path:"/impact-operationnel/:type/:id",
    name:"impact-operationnel",
    component: Organisationnel,
  },
  {
    path:"/recapitulatif-rto/:type/:id",
    name:"recapitulatif-rto",
    component: RecapRto,
  },
  {
    path:"/details-activites/:type/:id",
    name:"details-activites",
    component: DetailsActivites,
  },
  {
    path:"/systemes/:type/:id",
    name:"systemes",
    component: Systemes,
  },
  {
    path:"/personnels/:type/:id",
    name:"personnels",
    component: Personnels,
  },
  {
    path:"/espaces/:type/:id",
    name:"espaces",
    component: Espace,
  },
  {
    path:"/donnees/:type/:id",
    name:"donnees",
    component: Donnees,
  },
  {
    path:"/donnees/:type/:id",
    name:"donnees",
    component: Donnees,
  },
  {
    path:"/internes/:type/:id",
    name:"internes",
    component: Internes,
  },
  {
    path:"/externes/:type/:id",
    name:"externes",
    component: Externes,
  },
  {
    path: "/grille",
    name: "grille",
    component: Grille,
  },
  {
    path: "/universe-types",
    name: "universe-types",
    component: UniverseTypes,
  },

  // END BIA ROUTES

  // BIA - Informatique

  {
    path: "/bia-informatique",
    name: "bia-informatique",
    component: BiaInformatique,
  },
  {
    path: "/bia-infra",
    name: "bia-infra",
    component: BiaInfra,
  },
  {
    path: "/bia-server",
    name: "bia-server",
    component: BiaServer,
  },
  {
    path: "/bia-data",
    name: "bia-data",
    component: BiaData,
  },
  {
    path: "/rapport-general",
    name: "rapport-general",
    component: RapportGeneral,
  },


  // End BIA - Informatique

  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
