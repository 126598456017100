<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});
</script>
<script>
import ArgonAlert from "@/components/ArgonAlert.vue";
import API from './api'

  export default {
   components: {
     ArgonAlert,
   },
    data(){
      return {
        user:{},
        email:'',
        password:'',
        alertFail:'',
        alertSuccess:'',
        alertTitle:'',
        alertText:'',

      }
    },
    mounted(){

    },
    methods:{
      // async getCampaigns() {
      //   const result = await API.getList(searchParams).then(res=>{
          
          
      //   }).catch(err=>{
      //     console.log('error >>>>', err)
      //   });
      //   },
      async onLogin(){
        if(!this.email || !this.password) return
        let credentials = {}

        credentials.email = this.email
        credentials.password = this.password

         await API.getLogin(credentials).then(res =>{
            if(res.data){
              if(res.data.data == 'Veuillez consulter votre boite mail pour confirmer votre compte'){
                this.alertSuccess = true
                this.alertTitle = 'Attention'
                this.alertText = res.data.data
              }else{
                this.user = res.data.data
                 localStorage.setItem('user', JSON.stringify(this.user))
                 this.$router.push('/structure')
              }
              
            }

        }).catch(err => {
          this.alertFail = true
          this.alertTitle = 'Attention'
          this.alertText = err.response.data.data
          console.log('error >>>>', err.response.data)
        })
      }
    }
  }
</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <!-- <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        /> -->
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <argon-alert v-if="alertFail" color='danger'>
                  <strong>{{alertTitle}}!</strong> {{alertText}}
                </argon-alert>
                <argon-alert v-if="alertSuccess" color='success'>
                  <strong>{{alertTitle}}!</strong> {{alertText}}
                </argon-alert>
                <div class="card-body">
                  <!-- <form role="form"> -->
                    <div class="mb-3">
                      <argon-input
                        v-model="email"
                        id="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        v-model="password"
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                      />
                    </div>
                    <argon-switch id="rememberMe" name="remember-me"
                      >Remember me</argon-switch
                    >

                    <div class="text-center">
                      <argon-button
                        @click="onLogin()"
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        >Sign in</argon-button
                      >
                    </div>
                  <!-- </form> -->
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a
                      href="javascript:;"
                      class="text-success text-gradient font-weight-bold"
                      >Sign up</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  "Attention is the new currency"
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
