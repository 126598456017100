<template>
    <div class="container-fluid">
        <button type="button" @click="newAnalyze" class="btn bg-white">Nouvelle analyse</button>
        <div class="row">
            <div @click="goTo('/grille')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-bullet-list-67"></i>
                <div class="bia-text">Grille d'evaluation</div>
            </div>
            <div @click="showModal('/univers-activites')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-atom"></i>
                <div class="bia-text">Univers des activites</div>
            </div>
            <div @click="showModal('/activites-critiques')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-sound-wave"></i>
                <div class="bia-text">Activites crtiques</div>
            </div>
            <div @click="showModal('/impact-operationnel')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-compass-04"></i>
                <div class="bia-text">Analyse impacte operationnel</div>
            </div>
            <div @click="showModal('/recapitulatif-rto')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-support-16"></i>
                <div class="bia-text">Recapitulatif RTO</div>
            </div>
            <div @click="showModal('/details-activites')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-align-left-2"></i>
                <div class="bia-text">Details des activites</div>
            </div>
            <div @click="showModal('/systemes')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-laptop"></i>
                <div class="bia-text">Systemes</div>
            </div>
            <div @click="showModal('/personnels')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-circle-08"></i>
                <div class="bia-text">Personnels cles</div>
            </div>
            <div @click="showModal('/espaces')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-paper-diploma"></i>
                <div class="bia-text">Espaces de travail</div>
            </div>
            <div @click="showModal('/donnees')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-money-coins"></i>
                <div class="bia-text">Donnees essentielles</div>
            </div>
            <div @click="showModal('/internes')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-ungroup"></i>
                <div class="bia-text">Dependances Internes</div>
            </div>
            <div @click="showModal('/externes')" class="col-md-6 col-lg-3 col-xs-12 card bia-box text-center">
                <i class="ni ni-ungroup"></i>
                <div class="bia-text">Dependances Externes</div>
            </div>
            
        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Choisir</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div>
                    <div class="modal-body d-flex justify-content-around">
                        <button type="button" class="btn btn-success"
                            data-bs-toggle="collapse" data-bs-target="#collapseEntities" aria-expanded="false" aria-controls="collapseEntities"
                        >Organigramme</button>
                        <button type="button" class="btn btn-primary"
                            data-bs-toggle="collapse" data-bs-target="#collapseProcesses" aria-expanded="false" aria-controls="collapseProcesses"
                        >Processus</button>
                        <!-- <button @click="onClickModal('process')" type="button" class="btn btn-primary">Processus</button> -->
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="collapseEntities">
                            <div class="card card-body">
                                <!-- Some placeholder content for the first collapse component of this multi-collapse example. This panel is hidden by default but revealed when the user activates the relevant trigger. -->
                                <label for="entity">Choisissez une entite</label>
                                <select v-model="entity" @change="onClickModal('orga')">
                                    <option v-for="entiti in entities" :value="entiti.id" :key="entiti.id">{{ entiti.name }}</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="collapse multi-collapse" id="collapseProcesses">
                            <div class="card card-body">
                                <!-- Some placeholder content for the second collapse component of this multi-collapse example. This panel is hidden by default but revealed when the user activates the relevant trigger. -->
                                <label for="process">Choisissez un processus</label>
                                <select v-model="process" @change="onClickModal('process')">
                                    <option v-for="preces in processes" :value="preces.id" :key="preces.id">{{ preces.name }}</option>
                                </select>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import * as bootstrap from 'bootstrap'
import api from './api'
    
    export default{
        data(){
            return{
                path: null,
                myModal: null,
                project: null,
                entities: [],
                processes: [],
                entity: null,
                process: null
            }
        },
        mounted(){
            this.onGetCurrentProject(),
            this.onGetEntities(),
            this.onGetProcess()
        },
        methods:{
            goTo(path){
                this.$router.push(path)
            },
            showModal(link){
                this.path = link
                if(this.project.type) {
                    this.project.orga ?  this.goTo(link + '/' + this.project.type + '/'+ this.project.orga) : this.goTo(link + '/' + this.project.type + '/'+ this.project.process)
                    return
                }
                this.myModal = new bootstrap.Modal(document.getElementById('exampleModal'))
                this.myModal.show()
            },
            async onClickModal(type){
                this.myModal.hide()
                let tempParams = {}
                tempParams.projectId = this.project.id
                tempParams.type = type
                type == 'orga' ? tempParams.orga = this.entity : tempParams.process = this.process
                await api.setProjectType(tempParams).then(res =>{
                    if(res.data){
                        this.project = res.data.data
                        this.project.orga ? this.goTo(this.path + '/' + this.project.type + '/' + this.entity) : this.goTo(this.path + '/' + this.project.type  + '/' + this.process)
                        // this.goTo(this.path + '/' + this.project.type)
                    }
                }).catch(err => {
                    console.log('error >>>>', err)
                })
            },
            async onGetCurrentProject(){
                await api.getCurrentProject().then(res =>{
                    if(res.data){
                        this.project = res.data.data
                    }
                })
            },
            async onGetEntities(){
                await api.getEntities().then(res =>{
                    if(res.data){
                        this.entities = res.data.data
                    }
                })
            },
            async onGetProcess(){
                await api.getProcesses().then(res =>{
                    if(res.data){
                        this.processes = res.data.data
                    }
                })
            },
            async newAnalyze(){
                await api.setNewAnalyze(this.project.id).then(res =>{
                    if(res.data){
                        this.onGetCurrentProject()
                    }
                })
            },

        }
    }
</script>
<style scoped>
    .bia-box{
        width: 500px;
        padding: 50px;
        margin: 20px;
        cursor: pointer;
    }
    .ni{
        font-size: 100px;
        font-weight: bolder;
        margin-bottom: 10px;
    }
    .bia-text{
        font-weight: bold;
        font-size: 25px;
    }
</style>